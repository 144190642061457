import React from "react";
import { TableUi } from "../../../ui/TableUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { Space, Tag } from "antd";
import { parseNumeric } from "../../../../helpers/utils";
import { FileDoneOutlined } from "@ant-design/icons";
const columns = (acciones) => {
  const { handleEliminar } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      render: (text, record, index) => (
        <>
          {text}{" "}
          {record?.nrocomprobantemovimiento && (
            <Tag color="processing">
              <FileDoneOutlined />{" "}
              {"NOTA SALIDA: " + record?.nrocomprobantemovimiento}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      render: (text, record) => (
        <>
          {text} <b>{record?.unidadmedida_prefijo}</b>
        </>
      ),
    },
    {
      title: "Costo",
      dataIndex: "costounitario",
      key: "costounitario",
      render: (text, record) => (
        <b>
          {record?.moneda_prefijo} {parseNumeric(text).toFixed(2)}
        </b>
      ),
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record, index) => (
        <Space wrap>
          <ButtonUi
            key="Eliminar"
            displayText={false}
            typeUi={TipoButtonConstants.EliminarTable}
            onClick={() => handleEliminar(index)}
            size="small"
          />
        </Space>
      ),
    },
  ];
};
export const InsumosResumenProduccionTable = ({ data, remove }) => {
  const handleEliminar = (index) => remove(index);
  return (
    <TableUi
      data={data}
      columns={columns({ handleEliminar })}
      rowKey="id"
      size="small"
    />
  );
};
