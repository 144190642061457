import React, { useEffect, useState } from "react";
import { get_mediospago_by_tipomediopago } from "../../../actions/comprobante";
import { SelectUi } from "../../ui/SelectUi";
import { Form } from "antd";

export const MedioPagoSelect = ({ tipomediopago, nameProps, ...props }) => {
  const [mediospagodata, setMediospagodata] = useState([]);
  const form = Form.useFormInstance();
  useEffect(() => {
    const OnLoadMediosPago = async (tipomediopago) => {
      const datos = await get_mediospago_by_tipomediopago(tipomediopago);
      setMediospagodata(datos);
      const OptionDefault =
        datos.filter((x) => x.pordefecto === true)?.[0] || datos?.[0];
      form.setFieldValue(nameProps, OptionDefault?.value);
    };
    OnLoadMediosPago(tipomediopago);
  }, [tipomediopago]);

  return <SelectUi data={mediospagodata} {...props} />;
};
