import { Space, Tag } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { TableSearchBasicUi } from "../../ui/TableSearchBasicUi";
import {
  contactoSetActive,
  contactoStartDelete,
} from "../../../actions/contacto";

const columns = (acciones) => [
  {
    title: "N°",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Nombre",
    dataIndex: ["persona", "nombrecompleto"],
    key: "nombrecompleto",
    filteredValue: [acciones?.filteredColumn],
    onFilter: (value, record) =>
      String(record.nombrecompleto)
        .toLowerCase()
        .includes(value.toLowerCase()) ||
      String(record.nrodocumento).toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Documento",
    dataIndex: ["persona", "nrodocumento"],
    key: "nrodocumento",
    render: (text, record) => (
      <>
        <h5
          style={{
            margin: 0,
          }}
        >
          {record.persona?.nrodocumento}
        </h5>
        <h6
          style={{
            margin: 0,
          }}
        >
          {record.persona?.tipodocumento_nombre}
        </h6>
      </>
    ),
    align: "center",
  },
  {
    title: "Email",
    dataIndex: ["persona", "email"],
    key: "email",
  },
  {
    title: "Telefono",
    dataIndex: ["persona", "telefono"],
    key: "telefono",
  },
  {
    title: "Dirección",
    dataIndex: ["persona", "direccion"],
    key: "direccion",
  },
  {
    title: "Estado",
    dataIndex: "state",
    key: "state",
    render: (text, record) =>
      text === true ? (
        <Tag icon={<CheckCircleOutlined />} color="green">
          Activo
        </Tag>
      ) : (
        <Tag icon={<MinusCircleOutlined />} color="red">
          Inactivo
        </Tag>
      ),
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record) => (
      <Space size="middle">
        <ButtonUi
          typeUi={TipoButtonConstants.EditarTable}
          displayText={false}
          onClick={() => acciones.handleEditar(record)}
        />
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          displayText={false}
          onClick={() => acciones.handleEliminar(record)}
        />
      </Space>
    ),
  },
];
export const ContactoTable = ({ data, loading, handleOpenModal }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const handleEditar = (data) => {
    dispatch(
      contactoSetActive({
        ...data?.persona,
        contactoid: data?.id,
        contactostate: data?.state,
        tiposcontacto: data?.tiposcontacto,
      })
    );
    handleOpenModal();
  };
  const handleEliminar = (data) => {
    const { persona } = data;
    showDeleteConfirm(
      "¿Está seguro de eliminar el contacto seleccionado?",
      `${persona.nombres}, ${persona.apepaterno} ${persona.apematerno}`,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(contactoStartDelete(data));
        }
      }
    );
  };
  const onSearch = (value) => {
    setSearchText(value);
  };
  return (
    <TableSearchBasicUi
      columns={columns({
        filteredColumn: searchText,
        handleEditar,
        handleEliminar,
      })}
      rowKey="id"
      bordered
      data={data}
      onSearch={onSearch}
      loading={loading}
      size="small"
    />
  );
};
