import { parseNumeric } from "./utils";

const fn_CalculoTotales_Item = ({
  preciounitario,
  cantidad,
  descuentos = 0,
  porcentajeigv,
}) => {
  porcentajeigv = parseNumeric(porcentajeigv);
  const valorunitario = parseNumeric(
    preciounitario / (1 + porcentajeigv / 100)
  );
  const valorigvunitario = parseNumeric((valorunitario * porcentajeigv) / 100);
  const valorigv = parseNumeric(cantidad * valorigvunitario);
  const valorventabruto = parseNumeric(valorunitario * cantidad);
  const valorventa = parseNumeric(cantidad * preciounitario - descuentos);
  const totalventa = parseNumeric(cantidad * preciounitario);
  return {
    valorunitario,
    valorigvunitario,
    valorigv,
    valorventabruto,
    valorventa,
    totalventa,
  };
};
export { fn_CalculoTotales_Item };
