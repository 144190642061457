import { types } from "../types/typesGenerales";

const initialState = {
  contactos: [],
  activeContacto: null,
  loading: false,
};
export const contactoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.contactoLoaded:
      return { ...state, contactos: action.payload };
    case types.contactoAdded:
      return {
        ...state,
        contactos: [...state.contactos, action.payload],
      };
    case types.contactoSetActive:
      return {
        ...state,
        activeContacto: action.payload,
      };
    case types.contactoClearActive:
      return {
        ...state,
        activeContacto: null,
      };
    case types.contactoUpdated:
      return {
        ...state,
        contactos: state.contactos.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.contactoDeleted:
      return {
        ...state,
        contactos: state.contactos.filter((e) => e.id !== action.payload.id),
        activeContacto: null,
      };
    case types.contactoLoading:
      return { ...state, loading: action.payload };
    case types.contactoLogout:
      return { ...initialState };
    default:
      return state;
  }
};
