import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../../ui/ModalUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { ProcesarResumenForm } from "./ProcesarResumenForm";
import { FormUi } from "../../../../ui/FormUi";
import { Form } from "antd";
import { showConfirm } from "../../../../../helpers/confirmModal";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { procesarResumenProduccionStartAdd } from "../../../../../actions/produccion";
const initialValues = {
  almacen: null,
  tipomovimiento: null,
};
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) form.resetFields();
  }, [form, prevOpen, open]);
};
export const ProcesarResumenModal = ({ open, data, handleCloseModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  useResetFormOnCloseModal({ form, open });
  const handleSubmit = (values) => {
    showConfirm(
      "¿Esta seguro de procesar el resumen de producción?",
      "*** Una vez realizado el proceso, todo cambio se hará directamente en el inventario",
      (isConfirmed) => {
        if (isConfirmed) {
          const valuesData = {
            tipomovimiento: values?.tipomovimiento,
            almacenorigen: values?.almacen,
            almacendestino: values?.almacen,
            empresa: activeEmpleado?.empresa?.id,
            detallescomprobantemovimiento: data?.detalleresumenproduccion.map(
              (rowData) => ({
                item: rowData?.item,
                cantidad: rowData?.cantidad,
                unidadmedida: rowData?.unidadmedida,
                costo: rowData?.costounitarioproduccionsoles,
                moneda: activeEmpleado?.empresa?.moneda,
                lotes: rowData?.partida
                  ? [
                      {
                        numero_lote: rowData?.partida,
                        cantidad: rowData?.cantidad,
                        fecha_fabricacion: dayjs().format(DateFormats.backend),
                        fecha_vencimiento: dayjs().format(DateFormats.backend),
                      },
                    ]
                  : [],
              })
            ),
            resumenproduccion: data?.id,
          };
          dispatch(procesarResumenProduccionStartAdd(valuesData));
          handleCloseModal();
        }
      }
    );
  };
  return (
    <ModalUi
      title="Procesar resumen producción"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={handleCloseModal}
      width={{
        xs: "90%",
        sm: "80%",
        md: "70%",
        lg: "60%",
        xl: "50%",
        xxl: "40%",
      }}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCloseModal}
        />,
        <ButtonUi
          key="Guardar"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormProcesarResumenProduccion"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="FormProcesarResumenProduccion"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <ProcesarResumenForm data={data} />
      </FormUi>
    </ModalUi>
  );
};
