import React, { useEffect, useState } from "react";
import { Form, Radio } from "antd";
import { get_tipocomprobantepagos_puntoventa_by_terminalcajero } from "../../../actions/comprobante";
import { TipoComprobanteSunatConstants } from "../../../types/typesConstants";

export const TipoComprobanteRadio = ({ terminalcajero, ...props }) => {
  const form = Form.useFormInstance();
  const [tiposcomprobantes, setTiposcomprobantes] = useState([]);
  const OnLoadTipoComprobantesTerminal = async (terminalcajero) => {
    const datos = await get_tipocomprobantepagos_puntoventa_by_terminalcajero(
      terminalcajero
    );
    setTiposcomprobantes(datos);
    const OptionDefault =
      datos.filter((x) => x.pordefecto === true)?.[0] || datos?.[0];
    form.setFieldsValue({
      tipocomprobante: OptionDefault?.value,
      tipodocumentosunat: OptionDefault?.tipodocumentosunat,
    });
  };

  useEffect(() => {
    if (terminalcajero) OnLoadTipoComprobantesTerminal(terminalcajero);
  }, [terminalcajero]);

  useEffect(() => {
    const tipocomprobante = tiposcomprobantes.filter(
      (x) => x.value === props?.value
    )[0];
    form.setFieldValue(
      "tipodocumentosunat",
      tipocomprobante?.tipodocumentosunat
    );
    if (
      tipocomprobante?.tipodocumentosunat.toString() !==
      TipoComprobanteSunatConstants.Factura
    )
      //Clientes-Varios
      form.setFieldValue("cliente", 0);
  }, [props?.value]);

  return (
    <Radio.Group
      block
      options={tiposcomprobantes}
      // defaultValue={defaulValue}
      optionType="button"
      buttonStyle="solid"
      {...props}
    />
  );
};
