import { Col, Form, Input, InputNumber, Row, Space } from "antd";
import React from "react";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { DetalleComprobanteMovimientoDropdown } from "./DetalleComprobanteMovimientoDropdown";
import { DetalleComprobanteMovimientoTable } from "./DetalleComprobanteMovimientoTable";
import { SelectUi } from "../../../../ui/SelectUi";
import { UnidadMedidaSelect } from "../../../reusable/UnidadMedidaSelect";
import { MonedasSelect } from "../../../../generales/monedas/MonedasSelect";

export const DetalleComprobanteMovimientoForm = ({ open }) => {
  const form = Form.useFormInstance();
  const detallescomprobantemovimiento = Form.useWatch(
    "detallescomprobantemovimiento",
    form
  );
  const comprobantemoviento_key = Form.useWatch(
    "comprobantemoviento_key",
    form
  );
  const tipoinsumo = Form.useWatch("tipoinsumo", form);
  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="comprobantemoviento_key" />
      <FormItemHiddenUi name="detallescomprobantemovimiento" />
      <FormItemHiddenUi name="insumosresumenproduccion" />
      <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Form.Item name="tipoinsumo" label="Buscar por">
            <SelectUi
              data={[
                { value: "MAPRIMA", label: "MATERIA PRIMA" },
                { value: "OTHINS", label: "OTROS INSUMOS" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      {tipoinsumo === "MAPRIMA" && (
        <>
          <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <DetalleComprobanteMovimientoDropdown
                placeholder="[-Seleccione-]"
                name="comprobantemovimiento_nombre"
                label="Buscar nota de salida producción de materia prima"
              />
            </Col>
          </Row>
          <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <DetalleComprobanteMovimientoTable
                data={comprobantemoviento_key && detallescomprobantemovimiento}
                open={open}
              />
            </Col>
          </Row>
        </>
      )}
      {tipoinsumo === "OTHINS" && (
        <>
          <FormItemHiddenUi name="unidadmedida_prefijo" />
          <FormItemHiddenUi name="moneda_prefijo" />
          <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="descripcion"
                label="Descripción de insumo y/o servicio"
                rules={[
                  {
                    required: tipoinsumo === "OTHINS",
                    message: "La descripción es obligatoria",
                  },
                ]}
              >
                <Input
                  placeholder="Ingresar otro insumo, material o servicio"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <Form.Item
                name="cantidad"
                label="Cantidad"
                rules={[
                  {
                    required: tipoinsumo === "OTHINS",
                    type: "number",
                    message: "Ingreso un número válido",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Cantidad"
                  precision={2}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <Form.Item
                name="unidadmedida"
                label="Unidad medida"
                rules={[
                  {
                    required: tipoinsumo === "OTHINS",
                    message: "Seleccione la unidad de medida",
                  },
                ]}
              >
                <UnidadMedidaSelect
                  placeholder="[-Seleccione-]"
                  onChange={(value, input) => {
                    form.setFieldValue("unidadmedida_prefijo", input?.prefijo);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <Form.Item label="Costo">
                <Space.Compact>
                  <Form.Item
                    name={["costo", "costounitario"]}
                    noStyle
                    rules={[
                      {
                        required: tipoinsumo === "OTHINS",
                        type: "number",
                        min: 0,
                        message: "Ingreso un número válido",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Costo"
                      precision={2}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["costo", "moneda"]}
                    noStyle
                    rules={[
                      {
                        required: tipoinsumo === "OTHINS",
                        message: "La moneda es obligatoria",
                      },
                    ]}
                  >
                    <MonedasSelect
                      placeholder="[-Seleccione-]"
                      onChange={(_, field) => {
                        form.setFieldValue("moneda_prefijo", field?.prefijo);
                      }}
                    />
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
