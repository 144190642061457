import { Descriptions, Form, Input, InputNumber, Space } from "antd";
import React from "react";
import { TipoMedioPagoSelect } from "../../reusable/TipoMedioPagoSelect";
import { MedioPagoSelect } from "../../reusable/MedioPagoSelect";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  PaymentMethodType,
  TipoButtonConstants,
} from "../../../../types/typesConstants";

const ItemMedioPagoNuevo = {
  id: new Date().getTime(),
  tipomediopago: PaymentMethodType.Efectivo,
  mediopago: null,
  labelmediopago: "",
  nrooperacion: null,
  monto: 0,
};

export const MediosPagoContentItem = ({ field, handleAdd, handleRemove }) => {
  const form = Form.useFormInstance();
  const { setFieldValue } = form;
  const tipomediopago = Form.useWatch(
    ["mediospago", field.name, "tipomediopago"],
    form
  );
  const prefijo = Form.useWatch("prefijo", form);

  return (
    <Descriptions
      column={{ xxl: 6, xl: 5, lg: 4, md: 3, sm: 1, xs: 1 }}
      className="pos__carrito__mediospago__list__item"
      size="small"
    >
      <Descriptions.Item>
        <Form.Item
          {...field}
          name={[field.name, "tipomediopago"]}
          style={{ width: "100%", padding: "0 10px", marginBottom: "0px" }}
        >
          <TipoMedioPagoSelect
            onChange={(value) => {
              setFieldValue(["mediospago", field.name, "mediopago"], null);
              setFieldValue(["mediospago", field.name, "nrooperacion"], null);
            }}
            style={{ width: "100%" }}
            placeholder="[-Seleccione-]"
          />
        </Form.Item>
      </Descriptions.Item>
      <Descriptions.Item>
        <Form.Item
          {...field}
          name={[field.name, "mediopago"]}
          style={{ width: "100%", padding: "0 10px", marginBottom: "0px" }}
          rules={[
            { required: true, message: "el medio de pago es obligatorio" },
          ]}
        >
          <MedioPagoSelect
            tipomediopago={tipomediopago || 0}
            nameProps={["mediospago", field.name, "mediopago"]}
            onChange={(value, fieldMedio) => {
              setFieldValue(["mediospago", field.name, "nrooperacion"], "");
              setFieldValue(
                ["mediospago", field.name, "labelmediopago"],
                fieldMedio?.children?.label
              );
            }}
            style={{ width: "100%" }}
            placeholder="[-Seleccione-]"
          />
        </Form.Item>
      </Descriptions.Item>
      <Descriptions.Item>
        <Form.Item
          {...field}
          name={[field.name, "nrooperacion"]}
          style={{ width: "100%", padding: "0 10px", marginBottom: "0px" }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  getFieldValue(["mediospago", field.name, "tipomediopago"]) !==
                  "EFE"
                ) {
                  if (value?.length < 1) {
                    return Promise.reject(
                      new Error("El número de operación es obligatorio")
                    );
                  }
                  return Promise.resolve();
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            placeholder="Ingrese N° Operación"
            disabled={tipomediopago === "EFE" ? true : false}
          />
        </Form.Item>
      </Descriptions.Item>
      <Descriptions.Item>
        <Form.Item
          {...field}
          name={[field.name, "monto"]}
          style={{ width: "100%", padding: "0 10px", marginBottom: "0px" }}
          rules={[
            { required: true, message: "el monto es obligatorio" },
            { type: "number", message: "Ingrese un número validó" },
          ]}
        >
          <InputNumber
            placeholder="Ingrese monto"
            style={{ width: "100%" }}
            precision={2}
            addonBefore={prefijo}
          />
        </Form.Item>
      </Descriptions.Item>
      <Descriptions.Item style={{ width: "10%", paddingTop: "5px" }}>
        <Space wrap>
          <ButtonUi
            typeUi={TipoButtonConstants.AgregarTable}
            displayText={false}
            onClick={() => handleAdd(ItemMedioPagoNuevo)}
            size="small"
          />
          <ButtonUi
            typeUi={TipoButtonConstants.Disminuir}
            displayText={false}
            onClick={() => handleRemove(field.name)}
            size="small"
          />
        </Space>
      </Descriptions.Item>
    </Descriptions>
  );
};
