import React, { useEffect, useRef } from "react";
import { Form } from "antd";
import { DetalleComprobanteMovimientoForm } from "./DetalleComprobanteMovimientoForm";
import { ModalUi } from "../../../../ui/ModalUi";
import { FormUi } from "../../../../ui/FormUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { messageError } from "../../../../../helpers/messageConfig";
import dayjs from "dayjs";
const initialValues = {
  id: dayjs().valueOf(),
  tipoinsumo: "MAPRIMA",
  comprobantemoviento_key: null,
  comprobantemovimiento_nombre: null,
  detallescomprobantemovimiento: null,
  insumosresumenproduccion: [],
  descripcion: null,
  cantidad: null,
  unidadmedida: null,
  costo: { moneda: null, costounitario: null },
};
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
export const DetalleComprobanteMovimientoModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, open });
  const handleSave = (values) => {
    if (values?.tipoinsumo === "MAPRIMA") {
      if (values?.insumosresumenproduccion?.length > 0) onCancelModal();
      else messageError("Seleccione al menos un insumo");
    } else onCancelModal();
  };
  return (
    <ModalUi
      title="Busqueda de insumos"
      open={open}
      onCancel={onCancelModal}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormDetalleComprobanteMovimiento"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="FormDetalleComprobanteMovimiento"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <DetalleComprobanteMovimientoForm open={open} />
      </FormUi>
    </ModalUi>
  );
};
