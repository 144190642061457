import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesCotizacion";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const key = "msgcotizaciones";
const routes = {
  cotizacion: "cotizacion/",
};
export const cotizacionesStartLoad = (filtros) => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(`${routes.cotizacion}`, {
        params: { ...filtros },
      });
      const data = resp.data;
      dispatch(cotizacionLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const cotizacionStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(`${routes.cotizacion}`, data);
      const { msg, cotizacion } = resp.data;
      dispatch(cotizacionAdded(cotizacion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const cotizacionStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.cotizacion}${data.id}/`,
        data
      );
      const { msg, cotizacion } = resp.data;
      dispatch(cotizacionUpdated(cotizacion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const cotizacionStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.cotizacion}${data.id}/`
      );
      const { msg } = resp.data;
      dispatch(cotizacionDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
const cotizacionLoaded = (cotizaciones) => ({
  type: types.cotizacionesLoaded,
  payload: cotizaciones,
});
const cotizacionAdded = (cotizacion) => ({
  type: types.cotizacionAdded,
  payload: cotizacion,
});
const cotizacionUpdated = (cotizacion) => ({
  type: types.cotizacionUpdated,
  payload: cotizacion,
});
const cotizacionDeleted = (cotizacion) => ({
  type: types.cotizacionDeleted,
  payload: cotizacion,
});
export const cotizacionSetActive = (cotizacion) => ({
  type: types.cotizacionSetActive,
  payload: cotizacion,
});
export const cotizacionClearActive = () => ({
  type: types.cotizacionClearActive,
});
// Extra Async Functions

export const getMonedas = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.cotizacion}get_monedas/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

const getVendedores = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.cotizacion}get_vendedores/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
const getAlmacenesEmpleadoSelect = async (empleado) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.cotizacion}get_almacenes_byempledo/`,
      {
        params: { empleado },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
const get_pdf_cotizacion = async (cotizacion) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.cotizacion}get_pdf_cotizacion/`,
      {
        responseType: "blob",
        params: { cotizacion },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export { getAlmacenesEmpleadoSelect, get_pdf_cotizacion, getVendedores };
