import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../../ui/ModalUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { Form } from "antd";
import dayjs from "dayjs";
import { FormUi } from "../../../../ui/FormUi";
import { ItemForm } from "./ItemForm";
import { useDispatch, useSelector } from "react-redux";
import { detalleResumenProduccionClearActive } from "../../../../../actions/produccion";
import { parseNumeric } from "../../../../../helpers/utils";
const initialValues = (data) => ({
  id: data?.id || dayjs().valueOf(),
  item: data?.item || null,
  item_nombre: data?.item_nombre || null,
  cantidad: data?.cantidad || null,
  unidadmedida: data?.unidadmedida || null,
  unidadmedida_prefijo: data?.unidadmedida_prefijo || null,
  costounitariomanoobrasoles: parseFloat(data?.costounitariomanoobrasoles) || 0,
  costounitariomateriaprimasoles:
    parseFloat(data?.costounitariomateriaprimasoles) || 0,
  costounitarioindirectosoles:
    parseFloat(data?.costounitarioindirectosoles) || 0,
  costounitarioproduccionsoles:
    parseFloat(data?.costounitarioproduccionsoles) || 0,
  partida: data?.partida || null,
});
const useResetFormOnCloseModal = ({
  form,
  open,
  activeDetalleResumenProduccion,
}) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) {
      dispatch(detalleResumenProduccionClearActive());
      form.resetFields();
    } else {
      //Abrir Modal
      if (activeDetalleResumenProduccion)
        form.setFieldsValue({
          ...activeDetalleResumenProduccion,
          cantidad: parseNumeric(activeDetalleResumenProduccion?.cantidad),
        });
      else
        form.setFieldsValue({ ...initialValues(), id: new dayjs().valueOf() });
    }
  }, [form, prevOpen, open]);
};
export const ItemModal = ({ open, handleCancelModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeDetalleResumenProduccion } = useSelector(
    (state) => state.produccion
  );

  useResetFormOnCloseModal({
    form,
    open,
    activeDetalleResumenProduccion,
  });
  const handleSubmit = () => handleCancelModal();
  const handleCloseModal = () => {
    dispatch(detalleResumenProduccionClearActive());
    handleCancelModal();
  };
  return (
    <ModalUi
      title="Items producidos"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={handleCloseModal}
      //   width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCloseModal}
        />,
        <ButtonUi
          key="Guardar"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormDetalleProduccion"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="FormDetalleProduccion"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeDetalleResumenProduccion)}
        handleSubmit={handleSubmit}
      >
        <ItemForm />
      </FormUi>
    </ModalUi>
  );
};
