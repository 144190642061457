import React from "react";
import { TableUi } from "../../../../ui/TableUi";
import { parseNumeric } from "../../../../../helpers/utils";
import { Col, Descriptions, Divider, Form, Row, Table, Tag } from "antd";
import { AlmacenSelect } from "../../../reusable/AlmacenSelect";
import { TipoMovimientoPorProduccionSelect } from "../reusable/TipoMovimientoPorProduccionSelect";
const columnas = [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Item",
    dataIndex: "item_nombre",
    key: "item_nombre",
    render: (text, record) => (
      <>
        {text}{" "}
        {!!record?.partida && (
          <Tag color="success">Lote : {record?.partida}</Tag>
        )}
      </>
    ),
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
    render: (text, record) => (
      <>
        {parseNumeric(text).toFixed(2)} <b>{record?.unidadmedida_prefijo}</b>
      </>
    ),
  },
  {
    title: "Costo unitario producción",
    dataIndex: "costounitarioproduccionsoles",
    key: "costounitarioproduccionsoles",
    render: (text) => (
      <>
        <b>S/ {parseNumeric(text).toFixed(2)}</b>
      </>
    ),
  },
  Table.EXPAND_COLUMN,
];
const items = ({
  costounitariomanoobrasoles,
  costounitariomateriaprimasoles,
  costounitarioindirectosoles,
}) => [
  {
    label: "Costo mano obra",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costounitariomanoobrasoles).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo materia prima",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costounitariomateriaprimasoles).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo indirecto",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costounitarioindirectosoles).toFixed(2)}</b>
      </div>
    ),
  },
];
const items_cab = (data) => [
  {
    label: "Descripción",
    children: <div style={{ paddingLeft: "10px" }}>{data?.descripcion}</div>,
    span: 3,
  },
  {
    label: "Costo mano obra",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(data?.costototalmanoobra).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo materia prima",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(data?.costototalmateriaprima).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo indirecto",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(data?.costototalindirecto).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo total",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(data?.costototalproduccion).toFixed(2)}</b>
      </div>
    ),
  },
];
const ExpandCostoProduccionDiv = ({ data }) => {
  return <Descriptions items={items(data)} column={3} size="small" />;
};
export const ProcesarResumenForm = ({ data }) => {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={12}>
          <Form.Item
            name="almacen"
            label="Almacén"
            rules={[{ required: true, message: "El almacén es obligatorio" }]}
          >
            <AlmacenSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={12}>
          <Form.Item
            name="tipomovimiento"
            label="Tipo movimiento"
            rules={[
              {
                required: true,
                message: "El tipo de movimiento es obligatorio",
              },
            ]}
          >
            <TipoMovimientoPorProduccionSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
      </Row>
      <Divider
        orientation="left"
        style={{ borderColor: "#1677ff", margin: "8px 0" }}
      >
        Detalle del resumen a procesar
      </Divider>
      <Row gutter={[16, 16]} style={{ paddingBottom: "12px" }}>
        <Col span={24}>
          <Descriptions
            bordered
            items={items_cab(data)}
            column={{
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 2,
              xxl: 3,
            }}
            size="small"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <TableUi
            data={data?.detalleresumenproduccion}
            columns={columnas}
            rowKey="id"
            size="small"
            expandable={{
              expandedRowRender: (record) => (
                <ExpandCostoProduccionDiv data={record} />
              ),
            }}
          />
        </Col>
      </Row>
    </>
  );
};
