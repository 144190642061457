import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesProducto";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const key = "msgproducto";
const routes = {
  item: "productos/",
};
export const productoStartLoading = (filter_type = null, query = null) => {
  return async (dispatch) => {
    dispatch(productosStatusLoading());
    try {
      const resp = await axiosConToken.get(routes.item, {
        params: { filter_type, query },
      });
      const body = resp.data;
      dispatch(productosLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(productosStatusLoading(false));
    }
  };
};
export const productoStartAddNew = (producto) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.item, producto);
      const body = resp.data;
      const { msg, producto: producto_data } = body;
      dispatch(productoAddNew(producto_data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const productoStartUpdate = (producto) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `productos/${producto.id}/`,
        producto
      );
      const body = resp.data;
      const { msg, producto: producto_data } = body;
      dispatch(productoUpdated(producto_data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const productoStartDelete = (producto) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`productos/${producto.id}/`);
      const { msg } = resp.data;
      dispatch(productoDeleted(producto));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const productoStartDuplicate = (producto_id) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.get(
        `productos/${producto_id}/duplicate_product/`
      );
      const body = resp.data;
      const { msg, producto: producto_data } = body;
      dispatch(productoAddNew(producto_data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const productoStartChangeStatusEnabled = (producto_id) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.get(
        `productos/${producto_id}/change_status_product/`
      );
      const body = resp.data;
      const { msg, producto: producto_data } = body;
      dispatch(productoUpdated(producto_data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const productosStatusLoading = (loading = true) => ({
  type: types.productoStatusLoading,
  payload: loading,
});
const productosLoaded = (productos) => ({
  type: types.productoLoaded,
  payload: productos,
});
const productoAddNew = (producto) => ({
  type: types.productoAddNew,
  payload: producto,
});
const productoUpdated = (producto) => ({
  type: types.productoUpdated,
  payload: producto,
});
const productoDeleted = (producto) => ({
  type: types.productoDeleted,
  payload: producto,
});
export const showModalListaPrecios = (visible = true) => ({
  type: types.productoShowModalListaPrecios,
  payload: visible,
});
export const productoSetActive = (producto) => ({
  type: types.productoSetActive,
  payload: producto,
});
export const productoPreviewSave = (producto) => ({
  type: types.productoPreviewSave,
  payload: producto,
});
export const productoClearActiveProducto = () => ({
  type: types.productoClearActive,
});
export const productoVarianteSetActive = (variante) => ({
  type: types.productoVarianteSetActive,
  payload: variante,
});
export const productoVarianteClearActive = () => ({
  type: types.productoVarianteClearActive,
});
export const getTiposAfectacionProducto = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.item}get_list_tipos_afectacion/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const getTiposItems = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.item}get_tipos_items/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const getCategoriasSelectOptions = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.item}get_categorias_select/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const getSubCategorias_by_categorias_SelectOptions = async (
  categoria
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.item}get_subcategorias_select_by_categorias/`,
      {
        params: { categoria },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const getMarcas_SelectOptions = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.item}get_marcas_select/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_proveedores_selectoptions = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.item}get_proveedores_select/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const getUnidadesMedida_SelectOptions = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.item}get_unidadesmedida_select/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_price_list_select = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.item}get_price_list_select/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_currencies_select = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.item}get_currencies_select/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_depot_select_by_employee = async (employee) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.item}get_depot_select_by_employee/`,
      { params: { employee } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const get_rutasproduccion_select = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.item}get_rutasproduccion/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
