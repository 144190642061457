import { Col, Form, Row } from "antd";
import React from "react";
import { MonedaRadio } from "../../reusable/MonedaRadio";

export const TipoCambioMonedaDiv = () => {
  return (
    <Row justify="end">
      <Col span={24}>
        <Form.Item
          name="moneda"
          rules={[
            {
              required: true,
              message: "El tipo de moneda es obligatorio",
            },
          ]}
          required={false}
        >
          <MonedaRadio disabled />
        </Form.Item>
      </Col>
    </Row>
  );
};
