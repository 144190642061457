import React from "react";
import { TableUi } from "../../../ui/TableUi";
import { Descriptions, Space, Table } from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { useDispatch } from "react-redux";
import { detalleResumenProduccionSetActive } from "../../../../actions/produccion";
import { parseNumeric } from "../../../../helpers/utils";

const items = ({
  costounitariomanoobrasoles,
  costounitariomateriaprimasoles,
  costounitarioindirectosoles,
  costounitarioproduccionsoles,
}) => [
  {
    label: "Costo mano obra",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costounitariomanoobrasoles).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo materia prima",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costounitariomateriaprimasoles).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo indirecto",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costounitarioindirectosoles).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: <b>Costo producción</b>,
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costounitarioproduccionsoles).toFixed(2)}</b>
      </div>
    ),
  },
];
const ExpandCostoProduccionDiv = ({ data }) => {
  return (
    <Descriptions
      bordered
      title="Costos unitario producción"
      items={items(data)}
      column={1}
      size="small"
      className="DivCostosunitariosproduccion"
    />
  );
};
const columns = (acciones) => {
  const { handleEditar, handleEliminar } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Item",
      dataIndex: "item_nombre",
      key: "item_nombre",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      render: (text, record) => (
        <>
          {parseNumeric(text).toFixed(2)} <b>{record?.unidadmedida_prefijo}</b>
        </>
      ),
    },
    {
      title: "Costo unitario producción",
      dataIndex: "costounitarioproduccionsoles",
      key: "costounitarioproduccionsoles",
      render: (text) => (
        <>
          <b>S/ {parseNumeric(text).toFixed(2)}</b>
        </>
      ),
    },
    Table.EXPAND_COLUMN,
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record, index) => (
        <Space wrap>
          <ButtonUi
            key="Editar"
            displayText={false}
            typeUi={TipoButtonConstants.EditarTable}
            onClick={() => handleEditar(record)}
            size="small"
          />
          <ButtonUi
            key="Eliminar"
            displayText={false}
            typeUi={TipoButtonConstants.EliminarTable}
            onClick={() => handleEliminar(index)}
            size="small"
          />
        </Space>
      ),
    },
  ];
};
export const DetalleResumenProduccionTable = ({
  data,
  remove,
  handleOpenModal,
}) => {
  const dispatch = useDispatch();
  const handleEditar = (row) => {
    dispatch(detalleResumenProduccionSetActive(row));
    handleOpenModal();
  };
  const handleEliminar = (index) => remove(index);
  return (
    <TableUi
      data={data}
      columns={columns({ handleEditar, handleEliminar })}
      rowKey="id"
      size="small"
      expandable={{
        expandedRowRender: (record) => (
          <ExpandCostoProduccionDiv data={record} />
        ),
      }}
    />
  );
};
