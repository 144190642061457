import { types } from "../types/typesProgramacion";

const initialState = {
  programacionesTejido: [],
  programacionesTintoreria: [],
  tareasproduccion: [],
  rutasproduccion: [],
  ordenesproduccion: [],
  maquinas: [],
  procesosproduccion: [],
  consolidadosTela: [],
  activeProgramacionTejido: null,
  activeDetalleProgramacionTejido: null,
  activeProgramacionTintoreria: null,
  activeDetalleProgramacionTintoreria: null,
  activeConsolidadoTela: null,
  activeTareaProduccion: null,
  activeRutaProduccion: null,
  activeOrdenProduccion: null,
  activeMaquina: null,
  activeProcesoProduccion: null,
  activeDetalleProcesoProduccion: null,
  visibleFormProgramacionTejido: false,
  visibleFormProgramacionTintoreria: false,
  visibleFormRutaProduccion: false,
  visibleFormOrdenProduccion: false,
  visibleFormProcesoProduccion: false,
  visibleFormConsolidadoTela: false,
  loading: false,

  resumenesproduccion: [],
  activeResumenProduccion: null,
  activeDetalleResumenProduccion: null,
  activeInsumoResumenProduccion: null,
};
export const produccionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.dataLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case types.programacionTejidoLoaded:
      return {
        ...state,
        programacionesTejido: action.payload,
      };
    case types.produccionShowFormProgramacionTejido:
      return {
        ...state,
        visibleFormProgramacionTejido: action.payload,
      };
    case types.programacionTejidoSetActive:
      return {
        ...state,
        activeProgramacionTejido: action.payload,
      };
    case types.programacionTejidoClearActive:
      return {
        ...state,
        activeProgramacionTejido: null,
      };
    case types.programacionTejidoInserted:
      return {
        ...state,
        programacionesTejido: [action.payload, ...state.programacionesTejido],
      };
    case types.programacionTejidoUpdated:
      return {
        ...state,
        programacionesTejido: state.programacionesTejido.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.programacionTejidoDeleted:
      return {
        ...state,
        programacionesTejido: state.programacionesTejido.filter(
          (e) => e.id !== action.payload.id
        ),
        activeProgramacionTejido: null,
      };
    case types.detalleProgramacionTejidoSetActive:
      return {
        ...state,
        activeDetalleProgramacionTejido: action.payload,
      };
    case types.detalleProgramacionTejidoClearActive:
      return {
        ...state,
        activeDetalleProgramacionTejido: null,
      };
    case types.programacionTejidoLogout:
      return { ...initialState };
    case types.programacionTintoreriaLoaded:
      return {
        ...state,
        programacionesTintoreria: action.payload,
      };
    case types.produccionShowFormProgramacionTintoreria:
      return {
        ...state,
        visibleFormProgramacionTintoreria: action.payload,
      };
    case types.programacionTintoreriaSetActive:
      return {
        ...state,
        activeProgramacionTintoreria: action.payload,
      };
    case types.programacionTintoreriaClearActive:
      return {
        ...state,
        activeProgramacionTintoreria: null,
      };
    case types.detalleProgramacionTintoreriaSetActive:
      return {
        ...state,
        activeDetalleProgramacionTintoreria: action.payload,
      };
    case types.detalleProgramacionTintoreriaClearActive:
      return {
        ...state,
        activeDetalleProgramacionTintoreria: null,
      };
    case types.programacionTintoreriaInserted:
      return {
        ...state,
        programacionesTintoreria: [
          action.payload,
          ...state.programacionesTintoreria,
        ],
      };
    case types.programacionTintoreriaUpdated:
      return {
        ...state,
        programacionesTintoreria: state.programacionesTintoreria.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.programacionTintoreriaDeleted:
      return {
        ...state,
        programacionesTintoreria: state.programacionesTintoreria.filter(
          (e) => e.id !== action.payload.id
        ),
        activeProgramacionTintoreria: null,
      };
    case types.programacionTintoreriaLogout:
      return { ...initialState };

    case types.maquinasLoaded:
      return {
        ...state,
        maquinas: action.payload,
      };
    case types.maquinasSetActive:
      return {
        ...state,
        activeMaquina: action.payload,
      };
    case types.maquinasClearActive:
      return {
        ...state,
        activeMaquina: null,
      };
    case types.maquinasInserted:
      return {
        ...state,
        maquinas: [action.payload, ...state.maquinas],
      };
    case types.maquinasUpdated:
      return {
        ...state,
        maquinas: state.maquinas.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.maquinasDeleted:
      return {
        ...state,
        maquinas: state.maquinas.filter((e) => e.id !== action.payload.id),
        activeMaquina: null,
      };
    case types.maquinasLogout:
      return { ...initialState };

    case types.tareasProduccionLoaded:
      return {
        ...state,
        tareasproduccion: action.payload,
      };
    case types.tareasProduccionSetActive:
      return {
        ...state,
        activeTareaProduccion: action.payload,
      };
    case types.tareasProduccionClearActive:
      return {
        ...state,
        activeTareaProduccion: null,
      };
    case types.tareasProduccionInserted:
      return {
        ...state,
        tareasproduccion: [action.payload, ...state.tareasproduccion],
      };
    case types.tareasProduccionUpdated:
      return {
        ...state,
        tareasproduccion: state.tareasproduccion.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.tareasProduccionDeleted:
      return {
        ...state,
        tareasproduccion: state.tareasproduccion.filter(
          (e) => e.id !== action.payload.id
        ),
        activeTareaProduccion: null,
      };
    case types.tareasProduccionLogout:
      return { ...initialState };

    case types.rutasProduccionLoaded:
      return {
        ...state,
        rutasproduccion: action.payload,
      };
    case types.rutasProduccionSetActive:
      return {
        ...state,
        activeRutaProduccion: action.payload,
      };
    case types.rutasProduccionClearActive:
      return {
        ...state,
        activeRutaProduccion: null,
      };
    case types.rutasProduccionInserted:
      return {
        ...state,
        rutasproduccion: [action.payload, ...state.rutasproduccion],
      };
    case types.rutasProduccionUpdated:
      return {
        ...state,
        rutasproduccion: state.rutasproduccion.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.rutasProduccionDeleted:
      return {
        ...state,
        rutasproduccion: state.rutasproduccion.filter(
          (e) => e.id !== action.payload.id
        ),
        activeRutaProduccion: null,
      };
    case types.rutasProduccionShowForm:
      return {
        ...state,
        visibleFormRutaProduccion: action.payload,
      };
    case types.rutasProduccionLogout:
      return { ...initialState };

    case types.ordenesProduccionLoaded:
      return {
        ...state,
        ordenesproduccion: action.payload,
      };
    case types.ordenesProduccionSetActive:
      return {
        ...state,
        activeOrdenProduccion: action.payload,
      };
    case types.ordenesProduccionClearActive:
      return {
        ...state,
        activeOrdenProduccion: null,
      };
    case types.ordenesProduccionInserted:
      return {
        ...state,
        ordenesproduccion: [action.payload, ...state.ordenesproduccion],
      };
    case types.ordenesProduccionUpdated:
      return {
        ...state,
        ordenesproduccion: state.ordenesproduccion.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.ordenesProduccionDeleted:
      return {
        ...state,
        ordenesproduccion: state.ordenesproduccion.filter(
          (e) => e.id !== action.payload.id
        ),
        activeOrdenProduccion: null,
      };
    case types.ordenesProduccionShowForm:
      return {
        ...state,
        visibleFormOrdenProduccion: action.payload,
      };

    case types.procesosProduccionLoaded:
      return {
        ...state,
        procesosproduccion: action.payload,
      };
    case types.procesosProduccionSetActive:
      return {
        ...state,
        activeProcesoProduccion: action.payload,
      };
    case types.procesosProduccionClearActive:
      return {
        ...state,
        activeProcesoProduccion: null,
      };
    case types.procesosProduccionInserted:
      return {
        ...state,
        procesosproduccion: [action.payload, ...state.procesosproduccion],
      };
    case types.procesosProduccionUpdated:
      return {
        ...state,
        procesosproduccion: state.procesosproduccion.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.procesosProduccionDeleted:
      return {
        ...state,
        procesosproduccion: state.procesosproduccion.filter(
          (e) => e.id !== action.payload.id
        ),
        activeProcesoProduccion: null,
      };
    case types.procesosProduccionShowForm:
      return {
        ...state,
        visibleFormProcesoProduccion: action.payload,
      };
    case types.detalleProcesoProduccionSetActive:
      return {
        ...state,
        activeDetalleProcesoProduccion: action.payload,
      };
    case types.detalleProcesoProduccionClearActive:
      return {
        ...state,
        activeDetalleProcesoProduccion: null,
      };
    case types.procesosProduccionLogout:
      return { ...initialState };
    case types.consolidadoTelaLoaded:
      return {
        ...state,
        consolidadosTela: action.payload,
      };
    case types.consolidadoTelaInserted:
      return {
        ...state,
        consolidadosTela: [action.payload, ...state.consolidadosTela],
      };
    case types.consolidadoTelaUpdated:
      return {
        ...state,
        consolidadosTela: state.consolidadosTela.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.consolidadoTelaDeleted:
      return {
        ...state,
        consolidadosTela: state.consolidadosTela.filter(
          (e) => e.id !== action.payload.id
        ),
        activeConsolidadoTela: null,
      };
    case types.consolidadoTelaSetActive:
      return {
        ...state,
        activeConsolidadoTela: action.payload,
      };
    case types.consolidadoTelaClearActive:
      return {
        ...state,
        activeConsolidadoTela: null,
      };
    case types.produccionShowFormConsolidadoTela:
      return {
        ...state,
        visibleFormConsolidadoTela: action.payload,
      };
    case types.resumenProduccionLoaded:
      return {
        ...state,
        resumenesproduccion: action.payload,
      };
    case types.resumenProduccionInserted:
      return {
        ...state,
        resumenesproduccion: [action.payload, ...state.resumenesproduccion],
      };
    case types.resumenProduccionUpdated:
      return {
        ...state,
        resumenesproduccion: state.resumenesproduccion.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.resumenProduccionDeleted:
      return {
        ...state,
        resumenesproduccion: state.resumenesproduccion.filter(
          (e) => e.id !== action.payload.id
        ),
        activeResumenProduccion: null,
      };
    case types.resumenProduccionSetActive:
      return {
        ...state,
        activeResumenProduccion: action.payload,
      };
    case types.resumenProduccionClearActive:
      return {
        ...state,
        activeResumenProduccion: null,
      };
    case types.detalleResumenProduccionSetActive:
      return {
        ...state,
        activeDetalleResumenProduccion: action.payload,
      };
    case types.detalleResumenProduccionClearActive:
      return {
        ...state,
        activeDetalleResumenProduccion: null,
      };
    case types.insumosResumenProduccionSetActive:
      return {
        ...state,
        activeInsumoResumenProduccion: action.payload,
      };
    case types.insumosResumenProduccionClearActive:
      return {
        ...state,
        activeInsumoResumenProduccion: null,
      };
    default:
      return state;
  }
};
