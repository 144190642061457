import { Dropdown, Form, Input } from "antd";
import React, { useState } from "react";
import { search_comprobantesmovimiento_produccion_by_param } from "../../../../../actions/produccion";

export const DetalleComprobanteMovimientoDropdown = ({
  name,
  label,
  ...restProps
}) => {
  const form = Form.useFormInstance();
  const [items, setItems] = useState([]);
  const set_Item = (key, label) =>
    form.setFieldsValue({
      comprobantemoviento_key: key,
      comprobantemovimiento_nombre: label,
    });

  const handleSearch = async (value, event) => {
    event.preventDefault();
    if (value?.length > 0) {
      const data = await search_comprobantesmovimiento_produccion_by_param(
        value
      );
      const options = data.map((x) => ({
        label: x.comprobantemovimiento,
        key: x?.id,
        row: { ...x },
      }));
      if (data?.length > 0) {
        setItems(options);
      } else {
        setItems([]);
        set_Item(null, null);
      }
    } else {
      //Clear useState and setFieldValue
      setItems([]);
      //   setItemsData([]);
      set_Item(null, null);
    }
  };
  const onClick = (data, { key }) => {
    const itemSelect = data?.find((x) => x.key === parseInt(key));
    set_Item(key, itemSelect?.label);
    form.setFieldValue(
      "detallescomprobantemovimiento",
      itemSelect?.row?.detallescomprobantemovimiento
    );
  };
  return (
    <Dropdown menu={{ items, onClick: (e) => onClick(items, e) }}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const item = getFieldValue("comprobantemoviento_key");
              if (item) return Promise.resolve();

              return Promise.reject(new Error("El insumo es obligatorio"));
            },
          }),
        ]}
      >
        <Input.Search
          allowClear
          enterButton
          onSearch={handleSearch}
          {...restProps}
        />
      </Form.Item>
    </Dropdown>
  );
};
