import { Form } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PosCarritoContentItem } from "./PosCarritoContentItem";
import { productocarritoClearActive } from "../../../../actions/venta";
import { messageSuccess } from "../../../../helpers/messageConfig";
import { parseNumeric } from "../../../../helpers/utils";
import { fn_CalculoTotales_Item } from "../../../../helpers/functions";

export const PosCarritoContent = () => {
  const form = Form.useFormInstance();
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  let { activeProductoCarrito } = useSelector((state) => state.venta);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    let productoscarrito = detallecomprobante || [];
    if (activeProductoCarrito) {
      const _first_item = productoscarrito?.length === 0;
      const { moneda, moneda_prefijo, moneda_prefijosunat } =
        activeProductoCarrito;
      if (_first_item)
        form.setFieldsValue({ moneda, moneda_prefijo, moneda_prefijosunat });

      const bExiste = productoscarrito?.find(
        (item) => item?.id === activeProductoCarrito?.id
      );
      if (!bExiste) {
        const porcentajeigv = parseNumeric(
          activeEmpleado?.empresa?.porcentajeigv
        );
        const { preciounitario, cantidad, descuentos } = activeProductoCarrito;
        const {
          valorunitario,
          valorigvunitario,
          valorigv,
          valorventabruto,
          valorventa,
          totalventa,
        } = fn_CalculoTotales_Item({
          preciounitario,
          cantidad,
          descuentos,
          porcentajeigv,
        });
        const _item = {
          ...activeProductoCarrito,
          valorunitario,
          valorigvunitario,
          valorigv,
          valorventabruto,
          valorventa,
          totalventa,
        };
        productoscarrito = [...productoscarrito, _item];
        form.setFieldValue("detallecomprobante", productoscarrito);
        messageSuccess("Item agregado...", 2.5);
        dispatch(productocarritoClearActive());
      }
    }
  }, [activeProductoCarrito]);
  return (
    <>
      <Form.List
        name="detallecomprobante"
        style={{ marginBottom: 0 }}
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error(
                    "Ingrese al menos 1 item en el detalle del comprobante de pago"
                  )
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field) => (
                <PosCarritoContentItem
                  key={field.key}
                  field={field}
                  handleAdd={add}
                  handleRemove={remove}
                />
              ))}
              {errors?.length > 0 && (
                <Form.Item>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              )}
            </>
          );
        }}
      </Form.List>
    </>
  );
};
