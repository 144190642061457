import { Dropdown, Form, Input } from "antd";
import React, { useState } from "react";
import { search_Items_by_param } from "../../../../actions/produccion";
export const InputSearchDropdown = ({ name, label, ...restProps }) => {
  const form = Form.useFormInstance();
  const [items, setItems] = useState([]);
  //   const [itemsData, setItemsData] = useState([]);

  const set_Item = (key, item_nombre) =>
    form.setFieldsValue({ item: key, item_nombre });

  const handleSearch = async (value, event) => {
    event.preventDefault();
    if (value?.length > 0) {
      const data = await search_Items_by_param(value);
      const options = data.map((item) => ({
        label: item?.label,
        key: item?.value,
      }));
      if (data?.length > 0) {
        setItems(options);
        // setItemsData(data);
      } else {
        setItems([]);
        // setItemsData([]);
        set_Item(null, null);
      }
    } else {
      //Clear useState and setFieldValue
      setItems([]);
      //   setItemsData([]);
      set_Item(null, null);
    }
  };
  const onClick = ({ key }) => {
    const itemSelect = items?.find((x) => x.key === parseInt(key));
    set_Item(key, itemSelect?.label);
  };

  return (
    <Dropdown menu={{ items, onClick }}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const item = getFieldValue("item");
              if (item) return Promise.resolve();

              return Promise.reject(new Error("El item es obligatorio"));
            },
          }),
        ]}
      >
        <Input.Search
          allowClear
          enterButton
          onSearch={handleSearch}
          {...restProps}
        />
      </Form.Item>
    </Dropdown>
  );
};
