import { Descriptions, Form, theme, InputNumber } from "antd";
import React, { useEffect } from "react";
import { parseNumeric } from "../../../../helpers/utils";
import { GrupoTipoAfectacionIGV } from "../../../../types/typesConstants";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";

const fnCalculosTotales = (detallescomprobantepago) => {
  const productoscarrito = detallescomprobantepago || [];
  //Calculos totales
  let montototalgravadas = 0;
  let montototalinafectas = 0;
  let montototalexoneradas = 0;
  let montototalgratuitas = 0;
  let montototalexportaciones = 0;
  let montoigvtotal = 0;
  let montototal = 0;
  productoscarrito?.forEach((e) => {
    if (e?.grupotipoafectacionigv === GrupoTipoAfectacionIGV.GRAVADA)
      montototalgravadas += parseNumeric(e.valorventabruto);
    else if (e?.grupotipoafectacionigv === GrupoTipoAfectacionIGV.EXONERADA)
      montototalexoneradas += parseNumeric(e.valorventabruto);
    else if (e?.grupotipoafectacionigv === GrupoTipoAfectacionIGV.INAFECTA)
      montototalinafectas += parseNumeric(e.valorventabruto);
    else if (e?.grupotipoafectacionigv === GrupoTipoAfectacionIGV.EXPORTACION)
      montototalexportaciones += parseNumeric(e.valorventabruto);
    else if (e?.grupotipoafectacionigv === GrupoTipoAfectacionIGV.GRATUITA)
      montototalgratuitas += parseNumeric(e.valorventabruto);
    montoigvtotal += parseNumeric(e.valorigv);
    montototal += parseNumeric(e.totalventa);
  });
  const montosubtotal = parseNumeric(
    montototalgravadas +
      montototalexoneradas +
      montototalinafectas +
      montototalexportaciones +
      montototalgratuitas
  );

  return {
    montototalgravadas,
    montototalexoneradas,
    montototalinafectas,
    montototalexportaciones,
    montototalgratuitas,
    montosubtotal,
    montoigvtotal,
    montototal,
  };
};
export const PosCarritoFooter = () => {
  const form = Form.useFormInstance();
  const moneda_prefijo = Form.useWatch("moneda_prefijo", form);
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  const { token } = theme.useToken();
  const inputStyle = {
    fontSize: 16,
    color: token.colorPrimary,
    fontWeight: "bold",
  };
  const labelStyle = {
    width: "75%",
    fontWeight: "bold",
    justifyContent: "right",
    alignItems: "center",
  };
  const contentStyle = {
    justifyContent: "end",
  };
  // TODO: Calculo de los totales del comprobante de venta
  useEffect(() => {
    const {
      montototalgravadas,
      montototalexoneradas,
      montototalinafectas,
      montototalexportaciones,
      montototalgratuitas,
      montosubtotal,
      montoigvtotal,
      montototal,
    } = fnCalculosTotales(detallecomprobante);
    form.setFieldsValue({
      montototalgravadas,
      montototalexoneradas,
      montototalinafectas,
      montosubtotal,
      montoigvtotal,
      montototal,
    });
  }, [detallecomprobante]);

  return (
    <>
      <FormItemHiddenUi name="montototalgravadas" />
      <FormItemHiddenUi name="montototalexoneradas" />
      <FormItemHiddenUi name="montototalinafectas" />
      <div className="pos__carrito__footer">
        <Descriptions
          column={1}
          size="small"
          className="pos__carrito__footer__totals"
        >
          <Descriptions.Item
            label="SUB-TOTAL"
            labelStyle={labelStyle}
            style={{ paddingBottom: 2 }}
            contentStyle={contentStyle}
          >
            <Form.Item name="montosubtotal" style={{ marginBottom: 0 }}>
              <InputNumber
                variant="borderless"
                readOnly={true}
                prefix={moneda_prefijo || "S/"}
                precision={2}
                style={inputStyle}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            label="IGV"
            labelStyle={labelStyle}
            style={{ paddingBottom: 2 }}
            contentStyle={contentStyle}
          >
            <Form.Item name="montoigvtotal" style={{ marginBottom: 0 }}>
              <InputNumber
                variant="borderless"
                readOnly={true}
                prefix={moneda_prefijo || "S/"}
                precision={2}
                style={inputStyle}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            label="TOTAL"
            labelStyle={labelStyle}
            style={{ paddingBottom: 2 }}
            contentStyle={contentStyle}
          >
            <Form.Item name="montototal" style={{ marginBottom: 0 }}>
              <InputNumber
                variant="borderless"
                readOnly={true}
                prefix={moneda_prefijo || "S/"}
                precision={2}
                style={inputStyle}
              />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};
