export const types = {
  dataLoading: "[generales] Loading Data",

  localLoaded: "[local] Locales Loaded",
  localSetActive: "[local] Local Set Active",
  localClearActive: "[local] Local Clear Active",
  localAdded: "[local] Local added",
  localUpdated: "[local] Local updated",
  localDeleted: "[local] Local deleted",
  localLogout: "[local] Local logout",

  contactoLoaded: "[contacto] contactos Loaded",
  contactoSetActive: "[contacto] Contacto Set Active",
  contactoClearActive: "[contacto] Contacto Clear Active",
  contactoAdded: "[contacto] Contacto added",
  contactoUpdated: "[contacto] Contacto updated",
  contactoDeleted: "[contacto] Contacto deleted",
  contactoLogout: "[contacto] Contacto logout",
  contactoLoading: "[contacto] Contacto Loading process",

  clienteLoaded: "[cliente] clientes Loaded",
  clienteSetActive: "[cliente] Cliente Set Active",
  clienteClearActive: "[cliente] Cliente Clear Active",
  clienteAdded: "[cliente] Cliente added",
  clienteUpdated: "[cliente] Cliente updated",
  clienteDeleted: "[cliente] Cliente deleted",
  clienteLogout: "[cliente] Cliente logout",
  clienteLoading: "[cliente] Cliente Loading process",

  proveedorLoaded: "[proveedor] Proveedores Loaded",
  proveedorSetActive: "[proveedor] Proveedor Set Active",
  proveedorClearActive: "[proveedor] Proveedor Clear Active",
  proveedorAdded: "[proveedor] Proveedor added",
  proveedorUpdated: "[proveedor] Proveedor updated",
  proveedorDeleted: "[proveedor] Proveedor deleted",
  proveedorLogout: "[proveedor] Proveedor logout",
  proveedorLoading: "[proveedor] Proveedor Loading process",

  empresaLoaded: "[empresa] Empresas Loaded",
  empresaSetActive: "[empresa] Empresa Set Active",
  empresaClearActive: "[empresa] Empresa Clear Active",
  empresaAdded: "[empresa] Empresa added",
  empresaUpdated: "[empresa] Empresa updated",
  empresaDeleted: "[empresa] Empresa deleted",
  empresaLogout: "[empresa] Empresa logout",

  monedasLoaded: "[moneda] monedas Loaded",
  monedaSetActive: "[moneda] moneda Set Active",
  monedaClearActive: "[moneda] moneda Clear Active",
  monedaAdded: "[moneda] moneda added",
  monedaUpdated: "[moneda] moneda updated",
  monedaDeleted: "[moneda] moneda deleted",
  monedaLogout: "[moneda] moneda logout",

  coloresLoaded: "[color] colores Loaded",
  colorSetActive: "[color] color Set Active",
  colorClearActive: "[color] color Clear Active",
  colorAdded: "[color] color added",
  colorUpdated: "[color] color updated",
  colorDeleted: "[color] color deleted",
  colorLogout: "[color] color logout",
  colorLoading: "[color] color Loading Data",
};
