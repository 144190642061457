import { Col, DatePicker, Form, Input, Row, Space, Tag, Tooltip } from "antd";
import React from "react";
import { CardUi } from "../../../ui/CardUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  EstadoResumenProduccionConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { DetalleResumenProduccionDiv } from "./DetalleResumenProduccionDiv";
import { InsumosResumenProduccionDiv } from "./InsumosResumenProduccionDiv";
import { DetalleCostosProduccionDiv } from "./DetalleCostosProduccionDiv";
import { resumenProduccionClearActive } from "../../../../actions/produccion";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const EstadoResumenProduccion = ({ estado, estado_nombre = "NUEVO" }) => {
  if (estado === EstadoResumenProduccionConstants.PROCESO) {
    return (
      <Tag icon={<SyncOutlined spin />} color="processing">
        {estado_nombre}
      </Tag>
    );
  } else if (estado === EstadoResumenProduccionConstants.FINALIZADO) {
    return (
      <Tag icon={<CheckCircleOutlined />} color="success">
        {estado_nombre}
      </Tag>
    );
  } else {
    return (
      <Tag icon={<PlusCircleOutlined />} color="cyan">
        {estado_nombre}
      </Tag>
    );
  }
};

export const ResumenProduccionForm = ({ handleCloseForm }) => {
  const dispatch = useDispatch();
  const { activeResumenProduccion } = useSelector((state) => state.produccion);
  const handleCancel = () => {
    dispatch(resumenProduccionClearActive());
    handleCloseForm();
  };
  return (
    <CardUi
      title={
        <Space wrap>
          <span>Resumen Producción</span>
          <EstadoResumenProduccion
            estado={
              activeResumenProduccion
                ? activeResumenProduccion?.estado
                : "nuevo"
            }
            estado_nombre={
              activeResumenProduccion
                ? "Estado : " + activeResumenProduccion?.estado_nombre
                : "Estado : NUEVO"
            }
          />
        </Space>
      }
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormResumenProduccion"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="estado" />
      <FormItemHiddenUi name="costototalmanoobra" />
      <FormItemHiddenUi name="costototalmateriaprima" />
      <FormItemHiddenUi name="costototalproduccion" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="descripcion"
            label="Descripción"
            rules={[
              {
                required: true,
                message: "La descripción es obligatoria",
              },
            ]}
          >
            <Input placeholder="Ingrese descripción" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item
            name="fechahorainicio"
            label="Fecha hora inicio"
            rules={[
              {
                required: true,
                message: "La fecha hora inicio es obligatorio",
              },
            ]}
          >
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item name="fechahorafin" label="Fecha hora fin">
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 8]}>
            <Col span={24}>
              <InsumosResumenProduccionDiv />
            </Col>
            <Col span={24}>
              <DetalleResumenProduccionDiv />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <DetalleCostosProduccionDiv />
        </Col>
      </Row>
    </CardUi>
  );
};
