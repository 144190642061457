import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { comprobantepagoReducer } from "./comprobantepagoReducer";
import { cotizacionReducer } from "./cotizacionReducer";
import { empleadoReducer } from "./empleadoReducer";
import { empresaReducer } from "./empresaReducer";
import { guiaremisionReducer } from "./guiaremisionReducer";
import { inventarioReducer } from "./inventarioReducer";
import { localReducer } from "./localReducer";
import { productoReducer } from "./productoReducer";
import { resumencomprobanteReducer } from "./resumencomprobanteReducer";
import { uiReducer } from "./uiReducer";
import { ventaReducer } from "./ventaReducer";
import { dashboardReducer } from "./dashboardReducer";
import { listaprecioReducer } from "./listaprecioReducer";
import { produccionReducer } from "./produccionReducer";
import { ComprasReducer } from "./compraReducer";
import { proveedorReducer } from "./proveedorReducer";
import { cajaReducer } from "./cajaReducer";
import { types } from "../types/typesAuth";
import { monedaReducer } from "./monedaReducer";
import { colorReducer } from "./colorReducer";
import { contactoReducer } from "./contactoReducer";

const appReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  producto: productoReducer,
  listaprecio: listaprecioReducer,
  inventario: inventarioReducer,
  venta: ventaReducer,
  local: localReducer,
  empleado: empleadoReducer,
  contacto: contactoReducer,
  comprobantepago: comprobantepagoReducer,
  cotizacion: cotizacionReducer,
  empresa: empresaReducer,
  resumencomprobante: resumencomprobanteReducer,
  guiaremision: guiaremisionReducer,
  dashboard: dashboardReducer,
  produccion: produccionReducer,
  compra: ComprasReducer,
  proveedor: proveedorReducer,
  caja: cajaReducer,
  moneda: monedaReducer,
  color: colorReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === types.authUsuarioLogout) {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
