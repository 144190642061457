import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import { get_tipospersona } from "../../../actions/contacto";

export const TipoPersonaRadio = ({ ...props }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_tipospersona();
      setData(datos);
    };
    OnLoad();
  }, []);

  return (
    <Radio.Group
      options={data}
      buttonStyle="solid"
      optionType="button"
      {...props}
    />
  );
};
