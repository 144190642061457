import React, { useEffect, useState } from "react";
import { Form, Table, Tag } from "antd";
import { TableSearchBasicUi } from "../../../../ui/TableSearchBasicUi";
const columns = [
  {
    title: "N°",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Insumo",
    dataIndex: "item_nombre",
    key: "item_nombre",
    render: (value, record, index) => (
      <>
        {record?.numero_lote && (
          <>
            {value} <Tag color="cyan">Lote {record?.numero_lote}</Tag>
          </>
        )}
        {record?.numero_serie && (
          <>
            {value} <Tag color="cyan">Serie {record?.numero_serie}</Tag>
          </>
        )}
        {!record?.numero_lote && !record?.numero_serie && <> {value}</>}
      </>
    ),
  },
  {
    title: "Comprobante",
    dataIndex: "nrocomprobantemovimiento",
    key: "nrocomprobantemovimiento",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
  },
  {
    title: "U.Medida",
    dataIndex: "unidadmedida_nombre",
    key: "unidadmedida_nombre",
  },
];

export const DetalleComprobanteMovimientoTable = ({ data, open }) => {
  const form = Form.useFormInstance();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    if (open) {
      setSelectedRowKeys([]);
    }
  }, [open]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      form.setFieldsValue({
        insumosresumenproduccion: selectedRows,
      });
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  // const onSelectChange = (newSelectedRowKeys) => {
  //   console.log('selectedRowKeys changed: ', newSelectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  return (
    <TableSearchBasicUi
      rowSelection={rowSelection}
      data={data}
      columns={columns}
      size="small"
      rowKey="id"
    />
  );
};
