import React, { useEffect, useState } from "react";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { useDispatch, useSelector } from "react-redux";
import { cajaAbiertaSetActive } from "../../../actions/caja";
import { uiCollapsedSidebar } from "../../../actions/ui";
import {
  PaymentMethodType,
  TypeDiscountConstanst,
} from "../../../types/typesConstants";
import { useNavigate } from "react-router-dom";
import {
  productocarritoSetActive,
  puntoventaSetCurrentStep,
} from "../../../actions/venta";
import { Form, Steps, theme } from "antd";
import { BusquedaProductoStep } from "./BusquedaProductoStep/BusquedaProductoStep";
import { InformacionPagoStep } from "./Step2/InformacionPagoStep";
import { PuntoVentaResultStep } from "./Step3/PuntoVentaResultStep";
import { comprobantepagoStartAdd } from "../../../actions/comprobante";
import { showConfirm } from "../../../helpers/confirmModal";

const initialValuesStep2 = (data = {}) => {
  return {
    terminalcajero:
      JSON.parse(localStorage.getItem("caja"))?.terminalcajero || "",
    tipocomprobante: null,
    seriecomprobante: null,
    tienedescuento: false,
    tipodescuento: TypeDiscountConstanst.Monto,
    valordescuento: 0,
    descuento: 0,
    montototalpagar: 0,
    montoingresado: 0,
    montodescuento: 0,
    montovuelto: 0,
    cliente: null,
    tipodocumentosunat: null,
  };
};
const steps = [
  { title: "Detalle de orden" },
  { title: "Información de pago" },
  { title: "Resultado" },
];

export const PuntoVentaScreen = () => {
  const dispatch = useDispatch();
  const { token } = theme.useToken();
  const [initialValuesFormStep2, setInitialValuesFormStep2] = useState(
    initialValuesStep2()
  );
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const [cajaabierta, setCajaabierta] = useState(
    JSON.parse(localStorage.getItem("caja")) || null
  );

  const navigate = useNavigate();
  const { activeCajaAbierta } = useSelector((state) => state.caja);
  const { currentStep } = useSelector((state) => state.venta);
  const { activeEmpleado } = useSelector((state) => state.auth);

  useEffect(() => {
    if (cajaabierta) {
      dispatch(cajaAbiertaSetActive(cajaabierta));
    } else {
      navigate("/caja");
    }
    dispatch(uiCollapsedSidebar(true));
    dispatch(puntoventaSetCurrentStep());
    return () => {
      setCajaabierta(null);
    };
  }, [cajaabierta, dispatch, navigate]);

  const handleSubmitSteps = (name, { values, forms }) => {
    if (name === "FormStep1") {
      dispatch(
        puntoventaSetCurrentStep({
          previewsForm: [...currentStep?.previewsForm, { previewForm: values }],
          current: currentStep?.current + 1,
        })
      );
      const initial = initialValuesStep2();
      const initialStep2 = {
        ...values,
        ...initial,
        montototalpagar: values?.montototal,
        montoingresado: values?.montototal,
        mediospago: [
          {
            id: new Date().getTime(),
            tipomediopago: PaymentMethodType.Efectivo,
            mediopago: "",
            labelmediopago: "",
            nrooperacion: "",
            monto: values?.montototal,
          },
        ],
      };
      setInitialValuesFormStep2(initialStep2);
    } else if (name === "FormStep2") {
      showConfirm(
        "Esta seguro de emitir el comprobante de pago",
        "",
        (isConfirmed) => {
          if (isConfirmed) {
            const detallescomprobantepago =
              initialValuesFormStep2?.detallecomprobante;
            const valuesForm = {
              ...values,
              detallescomprobantepago,
              aperturacaja: activeCajaAbierta?.id,
              empresa: activeEmpleado?.empresa?.id,
            };
            dispatch(comprobantepagoStartAdd(valuesForm));
            dispatch(
              puntoventaSetCurrentStep({
                ...currentStep,
                current: currentStep?.current + 1,
              })
            );
          }
        }
      );
    } else if (name === "FormDetalleLoteSerieSalida") {
      const valuesLotes = values?.lotes
        ?.filter((e) => e.cantidad_ingresada !== null)
        .map((e) => ({
          ...e,
          cantidad: e.cantidad_ingresada,
        }));
      dispatch(
        productocarritoSetActive({
          ...values?.producto,
          cantidad: values?.cantidad,
          totalventa: parseFloat(
            parseFloat(values?.cantidad * values?.producto.precioventa).toFixed(
              2
            )
          ),
          lotes: valuesLotes,
          series: values?.series?.filter((item) => item.selected === true),
        })
      );
    }
  };

  const forms = [
    <BusquedaProductoStep stepsLength={steps.length} />,
    <InformacionPagoStep
      initialValues={initialValuesFormStep2}
      stepsLength={steps.length}
    />,
    <PuntoVentaResultStep stepsLength={steps.length} />,
  ];
  const contentStyle = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 10,
    padding: 10,
  };
  return (
    <PageHeaderUi title="Punto de venta">
      <Steps current={currentStep?.current || 0} items={items} />
      <div style={contentStyle}>
        <Form.Provider onFormFinish={handleSubmitSteps}>
          {forms[currentStep?.current || 0]}
        </Form.Provider>
      </div>
    </PageHeaderUi>
  );
};

export default PuntoVentaScreen;
