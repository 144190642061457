import { Col, Row, Form, Input, Divider } from "antd";
import React from "react";
import { DepartamentoSelect } from "../../ubigeos/DepartamentoSelect";
import { ProvinciaSelect } from "../../ubigeos/ProvinciaSelect";
import { DistritoSelect } from "../../ubigeos/DistritoSelect";
import { TiposContactoSelect } from "../TiposContactoSelect";

export const ContactoDireccionPart = () => {
  const form = Form.useFormInstance();
  const departamento = Form.useWatch("departamento", form);
  const provincia = Form.useWatch("provincia", form);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item name="telefono" label="Telefono/Movil" className="mb-1">
            <Input placeholder="Ingrese telefono/movil" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={10}>
          <Form.Item name="email" label="Email" className="mb-1">
            <Input placeholder="Ingrese email" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="tiposcontacto"
            label="Tipo contacto"
            rules={[
              {
                required: true,
                message: "El tipo de contacto es obligatorio",
              },
            ]}
          >
            <TiposContactoSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" style={{ margin: "10px 0" }}>
        Dirección
      </Divider>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item label="Departamento" name="departamento">
            <DepartamentoSelect
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item label="Provincia" name="provincia">
            <ProvinciaSelect
              departamento={departamento}
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item label="Distrito" name="ubigeodireccion">
            <DistritoSelect
              provincia={provincia}
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item name="direccion" label="Dirección" className="mb-1">
            <Input placeholder="Ingrese dirección" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
