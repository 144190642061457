import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesGenerales";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const routes = { contactos: "contactos/" };

export const contactosStartLoad = () => {
  return async (dispatch) => {
    dispatch(contactoLoading());
    try {
      const resp = await axiosConToken.get(routes.contactos);
      const data = resp.data;
      dispatch(contactosLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    } finally {
      dispatch(contactoLoading(false));
    }
  };
};
export const contactoStartAdd = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.contactos, data);
      const { msg, contacto } = resp.data;
      dispatch(contactoAdded(contacto));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
export const contactoStartUpdate = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.contactos}${data.id}/`,
        data
      );
      const { msg, contacto } = resp.data;
      dispatch(contactoUpdated(contacto));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
export const contactoStartDelete = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Eliminando...");
    try {
      const resp = await axiosConToken.delete(`${routes.contactos}${data.id}/`);
      const { msg } = resp.data;
      dispatch(contactoDeleted(data));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
const contactosLoaded = (contactos) => ({
  type: types.contactoLoaded,
  payload: contactos,
});
const contactoAdded = (contacto) => ({
  type: types.contactoAdded,
  payload: contacto,
});
const contactoUpdated = (contacto) => ({
  type: types.contactoUpdated,
  payload: contacto,
});
const contactoDeleted = (contacto) => ({
  type: types.contactoDeleted,
  payload: contacto,
});
export const contactoSetActive = (contacto) => ({
  type: types.contactoSetActive,
  payload: contacto,
});
export const contactoClearActive = () => ({ type: types.contactoClearActive });
export const contactoLoading = (loading = true) => ({
  type: types.contactoLoading,
  payload: loading,
});
//TODO: Implement extra functions
// ASYNC FUNCTIONS
const get_tipospersona = async () => {
  try {
    const resp = await axiosConToken.get(
      `${routes.contactos}get_tipospersona/`
    );
    return resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
};
const get_tiposcontacto = async () => {
  try {
    const resp = await axiosConToken.get(
      `${routes.contactos}get_tiposcontacto/`
    );
    return resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
};
export const get_clientes_by_tipocliente = async (tipocliente) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.clientes}get_clientes_by_tipocliente/`,
      { params: { tipocliente } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_proveedores = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`clientes/get_proveedores/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export { get_tipospersona, get_tiposcontacto };
