import React from "react";
import { EstadoOrdenProduccion } from "../../../types/typesConstants";
import { Tag } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const EstadoProduccionTag = ({ estado, estado_nombre }) => {
  switch (estado) {
    case EstadoOrdenProduccion.FINALIZADO:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {estado_nombre}
        </Tag>
      );
    case EstadoOrdenProduccion.PROCESO:
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          {estado_nombre}
        </Tag>
      );
    case EstadoOrdenProduccion.CANCELADO:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {estado_nombre}
        </Tag>
      );
    default:
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          {estado_nombre}
        </Tag>
      );
  }
};
