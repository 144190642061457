import { Col, Descriptions, Form, Input, InputNumber, Row } from "antd";
import React from "react";
import { InputSearchDropdown } from "../../reusable/ItemSearchDropdown";
import { UnidadMedidaSelect } from "../../../reusable/UnidadMedidaSelect";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { parseNumeric } from "../../../../../helpers/utils";
const validateNumber = (_, value) => {
  if (!value) {
    return Promise.reject(new Error("el número es obligatorio"));
  }
  if (isNaN(value)) {
    return Promise.reject(new Error("Ingrese un número válido"));
  }
  return Promise.resolve();
};
const itemsTotales = ({
  costounitarioproduccionsoles,
  handleChangeCostoManoObra,
  handleChangeCostoIndirecto,
}) => {
  return [
    {
      key: "costounitariomanoobrasoles",
      label: "Costo mano obra",
      children: (
        <Form.Item
          name="costounitariomanoobrasoles"
          rules={[{ validator: validateNumber }]}
        >
          <InputNumber
            addonBefore="S/"
            placeholder="Costo unitario mano obra"
            precision={2}
            style={{ width: "100%" }}
            onChange={handleChangeCostoManoObra}
          />
        </Form.Item>
      ),
    },
    {
      key: "costounitariomateriaprimasoles",
      label: "Costo materia prima",
      children: (
        <Form.Item name="costounitariomateriaprimasoles">
          <InputNumber
            addonBefore="S/"
            placeholder="Costo unitario materia prima"
            precision={2}
            style={{ width: "100%" }}
            disabled={true}
            variant="borderless"
          />
        </Form.Item>
      ),
    },
    {
      key: "costounitarioindirectosoles",
      label: "Costo indirecto",
      children: (
        <Form.Item name="costounitarioindirectosoles">
          <InputNumber
            addonBefore="S/"
            placeholder="Costo indirecto"
            precision={2}
            style={{ width: "100%" }}
            variant="borderless"
            disabled={true}
            onChange={handleChangeCostoIndirecto}
          />
        </Form.Item>
      ),
    },
    {
      key: "costoproduccion",
      label: "Costo producción",
      children: <b>S/ {costounitarioproduccionsoles}</b>,
      contentStyle: { fontSize: "16px" },
    },
  ];
};
export const ItemForm = () => {
  const form = Form.useFormInstance();
  const costounitariomanoobrasoles = Form.useWatch(
    "costounitariomanoobrasoles",
    form
  );
  const costounitariomateriaprimasoles = Form.useWatch(
    "costounitariomateriaprimasoles",
    form
  );
  const costounitarioindirectosoles = Form.useWatch(
    "costounitarioindirectosoles",
    form
  );
  const costounitarioproduccionsoles = Form.useWatch(
    "costounitarioproduccionsoles",
    form
  );
  const handleChangeUnidadMedida = (value, field) =>
    form.setFieldsValue({ unidadmedida_prefijo: field?.prefijo });
  const handleChangeCostoManoObra = (value) => {
    const _costounitarioproduccionsoles = parseNumeric(
      value +
        parseNumeric(costounitarioindirectosoles) +
        parseNumeric(costounitariomateriaprimasoles)
    );
    form.setFieldValue(
      "costounitarioproduccionsoles",
      _costounitarioproduccionsoles
    );
  };
  const handleChangeCostoIndirecto = (value) => {
    const _costounitarioproduccionsoles = parseNumeric(
      parseNumeric(costounitariomanoobrasoles) +
        value +
        parseNumeric(costounitariomateriaprimasoles)
    );
    form.setFieldValue(
      "costounitarioproduccionsoles",
      _costounitarioproduccionsoles
    );
  };

  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="item" />
      <FormItemHiddenUi name="moneda_prefijosunat" />
      <FormItemHiddenUi name="unidadmedida_prefijo" />
      <FormItemHiddenUi name="costounitarioproduccionsoles" />
      <Row gutter={[8]}>
        <Col span={24}>
          <InputSearchDropdown
            name="item_nombre"
            label="Item producido"
            placeholder="[-Seleccione item-]"
          />
        </Col>
        <Col span={12}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatoria" },
              {
                type: "number",
                min: 0.01,
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber
              placeholder="Cantidad producida"
              precision={2}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unidadmedida"
            label="U.Medida"
            rules={[
              { required: true, message: "La unidad medida es obligatorio" },
            ]}
          >
            <UnidadMedidaSelect
              placeholder="[-Seleccione-]"
              onChange={handleChangeUnidadMedida}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8]}>
        <Col span={12}>
          <Form.Item name="partida" label="Lote">
            <Input placeholder="Ingrese partida" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8]}>
        <Col span={24}>
          <Descriptions
            title="Costos producción"
            column={1}
            colon={false}
            items={itemsTotales({
              costounitarioproduccionsoles,
              handleChangeCostoManoObra,
              handleChangeCostoIndirecto,
            })}
            className="costosproduccionitem"
            contentStyle={{ justifyContent: "end" }}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};
