import React from "react";
import { useSelector } from "react-redux";
import { InventarioSearch } from "./InventarioSearch";
import { RemoveModal } from "./modal/RemoveModal";
import { PageHeaderUi } from "../ui/PageHeaderUi";
import { EntradaScreen } from "./movimientos/entrada/EntradaScreen";
import { SalidaScreen } from "./movimientos/salida/SalidaScreen";
import { TrasladoScreen } from "./movimientos/traslado/TrasladoScreen";
import { RemoveScreen } from "./movimientos/remover/RemoveScreen";

const InventarioProductoListScreen = () => {
  return (
    <PageHeaderUi title="Existencias" subTitle="Listado">
      <div style={{ overflow: "hidden" }}>
        <InventarioSearch />
      </div>
      <RemoveModal />
    </PageHeaderUi>
  );
};

export const InventarioProductoList = () => {
  const {
    visibleFormInput,
    visibleFormOutput,
    visibleFormTransfer,
    visibleFormRemove,
  } = useSelector((state) => state.inventario);
  if (visibleFormInput) {
    return <EntradaScreen />;
  } else if (visibleFormOutput) {
    return <SalidaScreen />;
  } else if (visibleFormTransfer) {
    return <TrasladoScreen />;
  } else if (visibleFormRemove) {
    return <RemoveScreen />;
  } else {
    return <InventarioProductoListScreen />;
  }
};

export default InventarioProductoList;
