import { Form, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { get_monedas } from "../../../actions/comprobante";

export const MonedaRadio = ({ ...props }) => {
  const form = Form.useFormInstance();
  const [monedas, setMonedas] = useState([]);
  useEffect(() => {
    const OnLoadData = async () => {
      const datos = await get_monedas();
      setMonedas(datos);
      const moneda = form.getFieldValue("moneda");
      if (!moneda) {
        const OptionDefault =
          datos.filter((x) => x.pordefecto === true)[0] || datos?.[0];
        form.setFieldsValue({
          moneda: OptionDefault?.value || null,
          moneda_prefijo: OptionDefault?.prefijo || null,
          moneda_prefijosunat: OptionDefault?.prefijosunat || null,
        });
      }
    };
    OnLoadData();
  }, []);
  // useEffect(() => {
  //   const value = props?.value;
  //   if (parseValid(value)) {
  //     const moneda = monedas.filter((x) => x.value === value)?.[0];
  //     if (parseValid(moneda)) {
  //       form.setFieldsValue({
  //         moneda_prefijo: moneda?.prefijo || null,
  //         moneda_prefijosunat: moneda?.prefijosunat || null,
  //       });
  //     }
  //   }
  // }, [props?.value]);

  return (
    <Radio.Group
      options={monedas}
      buttonStyle="solid"
      optionType="button"
      {...props}
      // onChange={OnChangeMoneda}
    />
  );
};
