import React, { useState } from "react";
import { CardUi } from "../../../ui/CardUi";
import { UnorderedListOutlined } from "@ant-design/icons";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { Col, Form, Row } from "antd";
import { DetalleResumenProduccionTable } from "./DetalleResumenProduccionTable";
import { ItemModal } from "./modal/ItemModal";

export const DetalleResumenProduccionDiv = () => {
  const form = Form.useFormInstance();
  const [openModalDetalle, setOpenModalDetalle] = useState(false);
  const handleOpenModalDetalle = () => setOpenModalDetalle(true);
  const handleCloseModalDetalle = () => setOpenModalDetalle(false);
  const detalleresumenproduccion = Form.useWatch(
    "detalleresumenproduccion",
    form
  );
  const insumosresumenproduccion = Form.useWatch(
    "insumosresumenproduccion",
    form
  );

  return (
    <>
      <CardUi
        title={
          <span>
            <UnorderedListOutlined /> Detalle resumen producción
          </span>
        }
        size="small"
        extra={
          <ButtonUi
            typeUi={TipoButtonConstants.AgregarTable}
            onClick={handleOpenModalDetalle}
          />
        }
      >
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.List
              name="detalleresumenproduccion"
              rules={[
                {
                  validator: async (_, value) => {
                    if (value < 1)
                      return Promise.reject(
                        new Error(
                          "Ingrese al menos un item en el detalle de proceso de producción"
                        )
                      );
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <DetalleResumenProduccionTable
                    data={detalleresumenproduccion}
                    remove={remove}
                    handleOpenModal={handleOpenModalDetalle}
                  />
                  {errors?.length > 0 && (
                    <Form.Item className="ui-errorlist">
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </CardUi>

      <ItemModal
        open={openModalDetalle}
        handleCancelModal={handleCloseModalDetalle}
        insumosresumenproduccion={insumosresumenproduccion}
      />
    </>
  );
};
