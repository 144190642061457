import React from "react";
import { ContactoPrincipalPart } from "./ContactoPrincipalPart";
import { ContactoDireccionPart } from "./ContactoDireccionPart";

export const ContactoForm = () => {
  return (
    <>
      <ContactoPrincipalPart />
      <ContactoDireccionPart />
    </>
  );
};
