import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Image,
  InputNumber,
  Row,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { productocarritoSetActive } from "../../../../actions/venta";
import { DetalleSalidaModal } from "./modal/DetalleSalidaModal";
import { messageError } from "../../../../helpers/messageConfig";
const { Text } = Typography;

export const ProductoCardItem = ({ producto }) => {
  const form = Form.useFormInstance();
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  const {
    nombre,
    descripcion,
    preciounitario,
    moneda_prefijo,
    stockactual,
    stockminimo,
  } = producto;
  const dispatch = useDispatch();
  const [cantidad, setCantidad] = useState(null);
  const [open, setOpen] = useState(false);
  const [validCantidad, setValidCantidad] = useState(true);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
    setCantidad(null);
  };
  const handleAddCart = (producto) => {
    if (cantidad === "" || cantidad === null) {
      setValidCantidad(false);
    } else {
      setValidCantidad(true);
      //Validado
      let productoscarrito = detallecomprobante || [];
      const _moneda = producto?.moneda;
      const bExiste =
        productoscarrito?.filter((item) => item?.id === producto?.id) > 0;
      const _bMonedaDif = productoscarrito?.some(
        (item) => item.moneda !== _moneda
      );
      if (!_bMonedaDif) {
        if (!bExiste) {
          if (producto?.manejalotes || producto?.manejaseries) showModal();
          else {
            dispatch(
              productocarritoSetActive({
                ...producto,
                cantidad,
              })
            );
            setCantidad(null);
          }
        }
      } else
        messageError("Solo se puede seleccionar items de un tipo de moneda");
    }
  };
  return (
    <>
      <Card size="small" style={{ borderRadius: "20px" }}>
        <Descriptions
          size="small"
          column={1}
          contentStyle={{ alignItems: "center", justifyContent: "center" }}
        >
          <Descriptions.Item>
            <Badge.Ribbon
              text={`Stock: ${stockactual}`}
              color={
                stockactual >= stockminimo
                  ? "green"
                  : stockactual === 0
                  ? "red"
                  : "gold"
              }
            >
              <Image
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                preview={false}
                style={{ borderRadius: "10px", width: "100%", height: "auto" }}
              />
            </Badge.Ribbon>
          </Descriptions.Item>
          <Descriptions.Item>
            <Text strong style={{ fontSize: "18px", color: "#2F58CD" }}>
              {`${moneda_prefijo} ${preciounitario}`}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item>
            <Text strong style={{ fontSize: "12px" }}>
              {nombre}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item>
            <Text type="secondary" style={{ fontSize: "10px" }}>
              {descripcion}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item>
            <Row>
              <Col sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  validateStatus={validCantidad ? "validating" : "error"}
                  help={!validCantidad ? "Ingrese cantidad valida" : ""}
                  style={{ marginBottom: "0px" }}
                >
                  <InputNumber
                    value={cantidad}
                    onChange={(value) => setCantidad(value)}
                    min={1}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} xl={12}>
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={() => handleAddCart(producto)}
                >
                  Añadir
                </Button>
              </Col>
            </Row>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <DetalleSalidaModal
        cantidad={cantidad}
        open={open}
        onCancel={hideModal}
        producto={producto}
      />
    </>
  );
};
