import { Form } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContactoForm } from "./ContactoForm";
import { ModalUi } from "./../../../ui/ModalUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  TipoButtonConstants,
  TipoDocumentoPersonaConstants,
  TipoPersonaConstants,
} from "../../../../types/typesConstants";
import { FormUi } from "../../../ui/FormUi";
import {
  contactoClearActive,
  contactoStartAdd,
  contactoStartUpdate,
} from "../../../../actions/contacto";
import dayjs from "dayjs";

const initialValues = (data = {}) => ({
  id: data?.id || null,
  tipopersona: data?.tipopersona || TipoPersonaConstants.NATURAL,
  tipodocumento: data?.tipodocumento || TipoDocumentoPersonaConstants.Dni,
  nrodocumento: data?.nrodocumento || null,
  nombrecompleto: data?.nombrecompleto || null,
  nombres: data?.nombres || null,
  apepaterno: data?.apepaterno || null,
  apematerno: data?.apematerno || null,
  departamento: data?.departamento || null,
  provincia: data?.provincia || null,
  ubigeodireccion: data?.ubigeodireccion || null,
  direccion: data?.direccion || null,
  telefono: data?.telefono || null,
  email: data?.email || null,
  website: data?.website || null,
  contactostate: data?.contactostate ? data?.contactostate : true,
  tiposcontacto: data?.tiposcontacto || [],
  contactoid: data?.contactoid || null,
});
const useResetFormOnCloseModal = ({ form, open, activeContacto }) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) {
      dispatch(contactoClearActive());
      form.resetFields();
    } else {
      //Abrir Modal
      if (activeContacto)
        form.setFieldsValue({
          ...activeContacto,
        });
      else
        form.setFieldsValue({ ...initialValues(), id: new dayjs().valueOf() });
    }
  }, [form, prevOpen, open]);
};
export const ContactoModal = ({ open, handleCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeContacto } = useSelector((state) => state.contacto);

  useResetFormOnCloseModal({
    form,
    open,
    activeContacto,
  });

  const handleSave = (values, _e) => {
    const _tiposcontacto = values?.tiposcontacto?.join(",");
    values = {
      persona: { ...values },
      id: values?.contactoid,
      tiposcontacto: _tiposcontacto,
      state: values?.contactostate,
    };
    if (activeContacto) {
      dispatch(contactoStartUpdate(values));
    } else {
      dispatch(contactoStartAdd(values));
    }
    handleCancel();
  };

  return (
    <ModalUi
      title={
        <>
          Contacto
          {activeContacto ? <small> Editar</small> : <small> Nuevo</small>}
        </>
      }
      open={open}
      onCancel={handleCancel}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
        <ButtonUi
          form="FormContactos"
          key="submit"
          htmlType="submit"
          typeUi={TipoButtonConstants.Guardar}
        />,
      ]}
      forceRender
    >
      <FormUi
        form={form}
        id="FormContactos"
        name="FormContactos"
        layout="vertical"
        autoComplete="off"
        handleSubmit={handleSave}
        initialValues={initialValues(activeContacto)}
      >
        <ContactoForm />
      </FormUi>
    </ModalUi>
  );
};
