import React, { useState } from "react";
import { TableSearchBasicUi } from "../../ui/TableSearchBasicUi";
import { Space, Tag } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { useDispatch } from "react-redux";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { colorSetActive, colorStartDelete } from "../../../actions/color";
const columns = ({ filteredColumn, handleEditar, handleEliminar }) => [
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
    filteredValue: [filteredColumn],
    onFilter: (value, record) =>
      String(record?.nombre).toLowerCase().includes(value.toLowerCase()) ||
      String(record?.codigohexadecimal)
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Cod.Hex",
    dataIndex: "codigohexadecimal",
    key: "codigohexadecimal",
    render: (text) => <Tag color={text}>{text}</Tag>,
  },

  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    render: (text, record) => (
      <Space wrap>
        <ButtonUi
          typeUi={TipoButtonConstants.EditarTable}
          displayText={false}
          size="small"
          onClick={() => handleEditar(record)}
        />
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          displayText={false}
          size="small"
          onClick={() => handleEliminar(record)}
        />
      </Space>
    ),
  },
];
export const ColorTable = ({ data, loading, handleOpenModal }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const handleEditar = (record) => {
    dispatch(colorSetActive(record));
    handleOpenModal();
  };
  const handleEliminar = (record) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el color seleccionado?",
      `${record.nombre}`,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(colorStartDelete(record));
        }
      }
    );
  };
  const onSearch = (value) => {
    setSearchText(value);
  };
  return (
    <TableSearchBasicUi
      columns={columns({
        filteredColumn: searchText,
        handleEditar,
        handleEliminar,
      })}
      colSpanSeach={12}
      rowKey="id"
      bordered
      data={data}
      onSearch={onSearch}
      loading={loading}
      size="small"
      sizeSearch="small"
    />
  );
};
