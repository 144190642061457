import { Descriptions, Form, InputNumber } from "antd";
import React, { useEffect } from "react";
import { parseNumeric } from "../../../../helpers/utils";
const items = ({
  costototalmanoobra,
  costototalmateriaprima,
  costototalproduccion,
  handleChangeCostoIndirecto,
}) => [
  {
    label: "Costo mano obra",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costototalmanoobra).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo materia prima",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costototalmateriaprima).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo indirecto",
    children: (
      <Form.Item
        name="costototalindirecto"
        rules={[
          {
            type: "number",
            required: true,
            message: "El número no es válido",
            min: 0,
          },
        ]}
      >
        <InputNumber
          addonBefore="S/"
          placeholder="Costo indirecto"
          precision={2}
          style={{ width: "100%" }}
          onChange={handleChangeCostoIndirecto}
        />
      </Form.Item>
    ),
  },
  {
    label: <b>Costo total</b>,
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costototalproduccion).toFixed(2)}</b>
      </div>
    ),
  },
];
export const DetalleCostosProduccionDiv = () => {
  const form = Form.useFormInstance();
  const costototalmanoobra = Form.useWatch("costototalmanoobra", form) || 0;
  const costototalmateriaprima =
    Form.useWatch("costototalmateriaprima", form) || 0;
  const costototalindirecto = Form.useWatch("costototalindirecto", form) || 0;
  const costototalproduccion = Form.useWatch("costototalproduccion", form) || 0;
  const detalleresumenproduccion = Form.useWatch(
    "detalleresumenproduccion",
    form
  );
  const insumosresumenproduccion = Form.useWatch(
    "insumosresumenproduccion",
    form
  );
  useEffect(() => {
    const _costototalmanoobra = parseNumeric(
      detalleresumenproduccion?.reduce(
        (accumulator, item) =>
          accumulator +
          parseFloat(item?.cantidad) *
            parseFloat(item?.costounitariomanoobrasoles),
        0
      )
    );
    const _costototalproduccion = parseNumeric(
      _costototalmanoobra + costototalmateriaprima + costototalindirecto
    );
    form.setFieldsValue({
      costototalmanoobra: _costototalmanoobra,
      costototalproduccion: _costototalproduccion,
    });
  }, [detalleresumenproduccion]);
  useEffect(() => {
    const _costototalmateriaprima = parseNumeric(
      insumosresumenproduccion?.reduce(
        (accumulator, item) =>
          accumulator +
          parseFloat(item?.cantidad) * parseFloat(item?.costounitario),
        0
      )
    );
    const _costototalproduccion = parseNumeric(
      costototalmanoobra + _costototalmateriaprima + costototalindirecto
    );

    form.setFieldsValue({
      costototalmateriaprima: _costototalmateriaprima,
      costototalproduccion: _costototalproduccion,
    });
  }, [insumosresumenproduccion, costototalmanoobra]);

  const handleChangeCostoIndirecto = (value) => {
    const _costototalproduccion = parseNumeric(
      costototalmanoobra + costototalmateriaprima + value
    );
    form.setFieldsValue({
      costototalproduccion: _costototalproduccion,
    });
  };
  return (
    <Descriptions
      bordered
      title="Costos Producción"
      items={items({
        costototalmanoobra,
        costototalmateriaprima,
        costototalproduccion,
        handleChangeCostoIndirecto,
      })}
      column={1}
      size="small"
    />
  );
};
