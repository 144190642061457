import React, { useEffect, useState } from "react";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ResumenProduccionTable } from "./ResumenProduccionTable";
import { useDispatch, useSelector } from "react-redux";
import { ResumenProduccionScreen } from "./ResumenProduccionScreen";
import { resumenProduccionStartLoading } from "../../../../actions/produccion";

const ResumenProduccionListScreen = ({ handleOpenForm }) => {
  const dispatch = useDispatch();
  const { resumenesproduccion } = useSelector((state) => state.produccion);
  const handleActualizar = () => dispatch(resumenProduccionStartLoading());

  const handleNuevo = () => handleOpenForm();

  return (
    <PageHeaderUi
      title="Resumen producción - Items"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
          key="Nuevo"
        />,
      ]}
    >
      <ResumenProduccionTable
        data={resumenesproduccion}
        handleOpenForm={handleOpenForm}
      />
    </PageHeaderUi>
  );
};
export const ResumenProduccionList = () => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);
  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);
  useEffect(() => {
    dispatch(resumenProduccionStartLoading());
  }, []);
  if (openForm)
    return <ResumenProduccionScreen handleCloseForm={handleCloseForm} />;
  else return <ResumenProduccionListScreen handleOpenForm={handleOpenForm} />;
};
