import { message } from "antd";
import { axiosConToken } from "./axios";
import { validaMessagesErrors } from "./validates";

const getTipoTerminal = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken("/terminalcajero/tipoterminales");
    datos = resp.data;
  } catch (error) {
    message.error(error.response);
  }
  return datos;
};
const getTipoEmisiones = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("terminalcajero/tipoemisiones");
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getTipoOperaciones = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("/terminalcajero/tipooperaciones");
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getTipoComprobantes = async (tipooperacion, tipoemision) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `terminalcajero/tiposcomprobantes/${tipooperacion}/${tipoemision}`
    );
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getTerminalesCajero = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`terminalcajero/getTerminalesCajero`);
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getClientes = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("clientes/");
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getTipoComprobantesTerminalCajero = async (terminalcajero) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "terminalcajero/getTipoComprobantesTerminalCajero",
      { params: { terminalcajero } }
    );
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getSerieComprobantesTerminalCajero = async (
  terminalcajero,
  tipocomprobante
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "terminalcajero/getSerieComprobantesTerminalCajero",
      { params: { terminalcajero, tipocomprobante } }
    );
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getTipoMedioPago = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`ventas/getTipoMediosPago`);
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getMedioPago = async (tipomediopago) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `ventas/getMediosPago/${tipomediopago}`
    );
    datos = resp.data;
  } catch (error) {
    message.error(error.response.data);
  }
  return datos;
};
const getEmpleados = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("terminalcajero/get_empleados/");
    datos = resp.data;
  } catch (error) {
    message.error(error.response);
  }
  return datos;
};
const getNotasVenta = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("comprobantepago/get_notasventas/");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
const getPdfNotaVenta = async (comprobante) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "comprobantepago/get_pdf_nota_venta/",
      {
        responseType: "blob",
        params: { comprobante },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

const searchProduct_by_store_and_name = async (store, name) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "cotizacion/search_product_by_store_and_name/",
      {
        params: { store, name },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export {
  getEmpleados,
  getTipoTerminal,
  getTipoComprobantes,
  getTipoEmisiones,
  getTipoOperaciones,
  getTerminalesCajero,
  getClientes,
  getTipoComprobantesTerminalCajero,
  getSerieComprobantesTerminalCajero,
  getTipoMedioPago,
  getMedioPago,
  getNotasVenta,
  getPdfNotaVenta,
  searchProduct_by_store_and_name,
};
