import React, { useState } from "react";
import { CardUi } from "../../../ui/CardUi";
import { UnorderedListOutlined } from "@ant-design/icons";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { Col, Form, Row } from "antd";
import { InsumosResumenProduccionTable } from "./InsumosResumenProduccionTable";
import { DetalleComprobanteMovimientoModal } from "./modal/DetalleComprobanteMovimientoModal";

export const InsumosResumenProduccionDiv = () => {
  const form = Form.useFormInstance();
  const [openModalInsumos, setOpenModalInsumos] = useState(false);
  const handleOpenModalInsumo = () => setOpenModalInsumos(true);
  const handleCloseModalInsumo = () => setOpenModalInsumos(false);
  const insumosresumenproduccion = Form.useWatch(
    "insumosresumenproduccion",
    form
  );
  return (
    <>
      <CardUi
        title={
          <span>
            <UnorderedListOutlined /> Insumos de producción
          </span>
        }
        size="small"
        extra={
          <ButtonUi
            typeUi={TipoButtonConstants.AgregarTable}
            onClick={handleOpenModalInsumo}
          />
        }
      >
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.List
              name="insumosresumenproduccion"
              rules={[
                {
                  validator: async (_, value) => {
                    if (value < 1)
                      return Promise.reject(
                        new Error("Ingrese al menos un insumos para producción")
                      );
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <InsumosResumenProduccionTable
                    data={insumosresumenproduccion}
                    remove={remove}
                  />
                  {errors?.length > 0 && (
                    <Form.Item className="ui-errorlist">
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </CardUi>

      <DetalleComprobanteMovimientoModal
        open={openModalInsumos}
        onCancelModal={handleCloseModalInsumo}
      />
    </>
  );
};
