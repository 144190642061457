import { Col, Form, Input, InputNumber, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { TipoTelaSelect } from "../../reusable/TipoTelaSelect";
import { AnchoTelaSelect } from "../../reusable/AnchoTelaSelect";
import { CardUi } from "../../../../ui/CardUi";
import { InsumosDetalleProgramacionList } from "./InsumosDetalleProgramacionList";
import { FileAddOutlined } from "@ant-design/icons";
import { MonedaSelect } from "./../../../../ventas/reusable/MonedaSelect";
import { detalleProgramacionTejidoSetActive } from "../../../../../actions/produccion";
import { useDispatch } from "react-redux";
import { SearchComprobanteCompraModal } from "./modal/SearchComprobanteCompraModal";
import { EstadoProgramacionSelect } from "../../reusable/EstadoProgramacionSelect";
import { EstadoProgramacionTejido } from "../../../../../types/typesConstants";

export const DetalleProgramacionTabItem = ({ field }) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const [openModalComprobante, setOpenModalComprobante] = useState(false);
  const [estadoValid, setEstadoValid] = useState(false);
  const handleOpenModalComprobante = () => setOpenModalComprobante(true);
  const handleHideModalComprobante = () => setOpenModalComprobante(false);
  const detalleprogramaciontejido = Form.useWatch(
    "detalleprogramaciontejido",
    form
  );
  const proveedor = Form.useWatch("proveedor", form);
  const estado = Form.useWatch(
    ["detalleprogramaciontejido", field.name, "estado"],
    form
  );
  useEffect(() => {
    if (estado !== EstadoProgramacionTejido.PROCESO) setEstadoValid(true);
    else setEstadoValid(false);
  }, [estado]);
  const handleChangeTipoTela = (value, item) => {
    form.setFields([
      {
        name: ["detalleprogramaciontejido", field.name, "precio"],
        value: item?.preciotejido,
      },
      {
        name: ["detalleprogramaciontejido", field.name, "moneda"],
        value: item?.moneda,
      },
    ]);
  };

  const handleSelectComprobanteCompra = (row, e) => {
    e.preventDefault();
    dispatch(detalleProgramacionTejidoSetActive(row));
    handleOpenModalComprobante();
  };
  return (
    <>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={16} lg={10} xl={8}>
          <Form.Item
            label="Tipo tela"
            name={[field.name, "tipotela"]}
            rules={[
              {
                required: true,
                message: "El tipo tela es obligatorio",
              },
            ]}
          >
            <TipoTelaSelect
              placeholder="[-Seleccione-]"
              onChange={handleChangeTipoTela}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={4}>
          <Form.Item
            label="Ancho de tela"
            name={[field.name, "anchotela"]}
            rules={[
              {
                required: true,
                message: "El ancho de tela es obligatorio",
              },
            ]}
          >
            <AnchoTelaSelect
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={5}>
          <Form.Item
            label="Cantidad (Rollos, Cuello,etc)"
            name={[field.name, "cantidad"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: "La cantidad es obligatoria",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese cantidad"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            name={[field.name, "estado"]}
            style={{ marginBottom: 0 }}
            label="Estado"
          >
            <EstadoProgramacionSelect />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            name={[field.name, "pesobruto"]}
            label="Peso bruto (Kg)"
            rules={[
              {
                required: estadoValid,
                message: "El peso bruto es obligatoria",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese peso bruto"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            name={[field.name, "pesoneto"]}
            label="Peso neto (Kg)"
            rules={[
              {
                required: estadoValid,
                message: "El peso neto es obligatorio",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese peso"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Precio Tejeduria"
            name={[field.name, "precio"]}
            rules={[
              {
                required: estadoValid,
                message: "El precio es obligatorio",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese precio"
              style={{ width: "100%" }}
              precision={4}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={5}>
          <Form.Item
            label="Moneda"
            name={[field.name, "moneda"]}
            rules={[
              {
                required: estadoValid,
                message: "La moneda es obligatoria",
              },
            ]}
          >
            <MonedaSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Tipo cambio"
            name={[field.name, "tipocambio"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: estadoValid,
                message: "El tipo de cambio es obligatoria",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese tipo cambio"
              style={{ width: "100%" }}
              precision={4}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={24} lg={14} xl={12}>
          <Space.Compact style={{ width: "100%" }}>
            <Form.Item
              name={[field.name, "detallecomprobantecompra_nombre"]}
              label="Comprobante compra"
              rules={[
                {
                  required: estadoValid,
                  message: "El comprobante de compra es obligatorio",
                },
              ]}
              style={{ marginBottom: 0, width: "100%" }}
            >
              <Input.Search
                readOnly
                placeholder="Buscar comprobante compra"
                allowClear
                enterButton
                onSearch={(_, e) =>
                  handleSelectComprobanteCompra(
                    detalleprogramaciontejido[field?.name],
                    e
                  )
                }
              />
            </Form.Item>
          </Space.Compact>
        </Col>
      </Row>
      <Row
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        style={{ paddingBottom: "12px" }}
      >
        <Col xs={24} sm={24} md={24} lg={16}>
          <Form.Item
            name={[field.name, "observacion"]}
            style={{ marginBottom: 0 }}
            label="Observaciones"
          >
            <Input placeholder="Observaciones" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col span={24}>
          <CardUi
            title={
              <span>
                <FileAddOutlined /> Insumos y/o materiales
              </span>
            }
            type="inner"
            size="small"
          >
            <InsumosDetalleProgramacionList field={field} />
          </CardUi>
        </Col>
      </Row>
      <SearchComprobanteCompraModal
        open={openModalComprobante}
        onCancel={handleHideModalComprobante}
        proveedor={proveedor}
      />
    </>
  );
};
