import { GroupsOfUser } from "../types/typesConstants";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  ControlOutlined,
  DesktopOutlined,
  FolderOutlined,
  HomeOutlined,
  PayCircleOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

import { ComprobantePagoList } from "../components/ventas/comprobantepago/ComprobantePagoList";
import { ComprobantePagoNoEnviadoList } from "../components/ventas/comprobantepago/noenviados/ComprobantePagoNoEnviadoList";
import { NotaVentaList } from "../components/ventas/comprobantepago/notaventa/NotaVentaList";
import { ResumenList } from "../components/ventas/comprobantepago/resumenes/ResumenList";
import { CotizacionList } from "../components/ventas/cotizacion/CotizacionList";
import { PuntoVentaScreen } from "../components/ventas/puntoventa/PuntoVentaScreen";
import { TerminalCajeroList } from "../components/ventas/terminalcajero/TerminalCajeroList";
import { CajasList } from "../components/ventas/caja/CajasList";
import { InventarioProductoList } from "../components/inventario/InventarioProductoList";
import { KardexList } from "../components/inventario/kardex/KardexList";
import { MovimientoTrasladoList } from "../components/inventario/traslados/MovimientoTrasladoList";

import { UsuarioList } from "../components/auth/usuario/UsuarioList";
import { Dashboard } from "../components/dashboard/Dashboard";
import { EmpresasList } from "../components/generales/empresas/EmpresasList";
import { LocalesList } from "../components/generales/locales/LocalesList";

import { ProductosList } from "../components/productos/ProductosList";
import { EmpleadoList } from "../components/rrhh/empleados/EmpleadoList";
// import { GeneralesSteps } from "../components/generales/prueba/GeneralesSteps";
import { CierreCajaScreen } from "../components/ventas/caja/cierrecaja/CierreCajaScreen";
import { ListaPrecioList } from "../components/items/listaprecio/ListaPrecioList";
import { ProgramacionTejidoList } from "./../components/produccion/planeacion/tejido/programacion/ProgramacionTejidoList";
import { ProgramacionTintoreriaList } from "../components/produccion/planeacion/tintoreria/programacion/ProgramacionTintoreriaList";
import { ComprobanteCompraList } from "../components/compras/comprobantecompra/ComprobanteCompraList";
import { TareaProduccionList } from "../components/produccion/proceso/rutaproduccion/tareaproduccion/TareaProduccionList";
import { RutaProduccionList } from "../components/produccion/proceso/rutaproduccion/RutaProduccionList";

import { MaquinaList } from "./../components/produccion/mantenimientos/maquina/MaquinaList";
import { ProcesoProduccionList } from "../components/produccion/proceso/procesoproduccion/ProcesoProduccionList";
import { MovimientosCajaList } from "../components/ventas/caja/movimientocaja/MovimientosCajaList";
import OrdenProduccionList from "../components/produccion/proceso/ordenproduccion/OrdenProduccionList";
import { ProveedorList } from "../components/generales/proveedores/ProveedorList";
import { MovimientoEntradaScreen } from "../components/inventario/movimientos/entrada/MovimientoEntradaScreen";
import { MovimientosList } from "../components/inventario/movimientos/MovimientosList";
import { GeneralesList } from "../components/generales/GeneralesList";
import { ContactoList } from "../components/generales/contactos/ContactoList";
import { ResumenProduccionList } from "../components/produccion/proceso/resumenproduccion/ResumenProduccionList";

const routesGeneral = [
  {
    id: 1,
    to: "",
    path: "/",
    Name: "Dashboard",
    Component: Dashboard,
    parentId: null,
    icon: <HomeOutlined />,
    isLink: true,
    isVisible: true,
    roles: [],
  },
];

const routeVentas = [
  {
    id: 2,
    to: "ventas",
    path: "ventas",
    Name: "Ventas",
    Component: null,
    parentId: null,
    icon: <ShoppingCartOutlined />,
    isLink: false,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 3,
    to: "/ventas/comprobantepago",
    path: "ventas/comprobantepago",
    Name: "Listado de comprobantes pago",
    Component: ComprobantePagoList,
    parentId: 2,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 4,
    to: "/ventas/comprobantenoenviadopago",
    path: "ventas/comprobantenoenviadopago",
    Name: "Comprobantes no enviados",
    Component: ComprobantePagoNoEnviadoList,
    parentId: 2,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 5,
    to: "/ventas/notaventa",
    path: "ventas/notaventa",
    Name: "Notas de Venta",
    Component: NotaVentaList,
    parentId: 2,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 6,
    to: "/ventas/resumencomprobantes",
    path: "ventas/resumencomprobantes",
    Name: "Resúmenes",
    Component: ResumenList,
    parentId: 2,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 7,
    to: "/ventas/cotizacion",
    path: "ventas/cotizacion",
    Name: "Cotizaciones",
    Component: CotizacionList,
    parentId: 2,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 8,
    to: "POS",
    path: "POS",
    Name: "POS",
    Component: null,
    parentId: null,
    icon: <ShopOutlined />,
    isLink: false,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 9,
    to: "/ventas/puntoventa",
    path: "ventas/puntoventa",
    Name: "Punto Venta",
    Component: PuntoVentaScreen,
    parentId: 8,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },

  {
    id: 12,
    to: "Caja",
    path: "Caja",
    Name: "Caja",
    Component: null,
    parentId: null,
    icon: <PayCircleOutlined />,
    isLink: false,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 13,
    to: "/caja/terminalcajero",
    path: "caja/terminalcajero",
    Name: "Terminal Cajero",
    Component: TerminalCajeroList,
    parentId: 12,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 14,
    to: "/caja",
    path: "caja",
    Name: "Caja",
    Component: CajasList,
    parentId: 12,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 15,
    to: "/caja/movimientoscaja",
    path: "caja/movimientoscaja",
    Name: "Egresos/Ingresos",
    Component: MovimientosCajaList,
    parentId: 12,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
  {
    id: 16,
    to: "/caja/arqueocaja",
    path: "caja/arqueocaja",
    Name: "Caja",
    Component: CierreCajaScreen,
    parentId: 12,
    icon: null,
    isLink: true,
    isVisible: false,
    roles: [GroupsOfUser.VENTAS, GroupsOfUser.JEFE_VENTAS],
  },
];
const routeCompras = [
  {
    id: 10,
    to: "Compras",
    path: "Compras",
    Name: "Compras",
    Component: null,
    parentId: null,
    icon: <ShoppingOutlined />,
    isLink: false,
    isVisible: true,
    roles: [GroupsOfUser.COMPRAS],
  },
  {
    id: 11,
    to: "/compras/comprobante",
    path: "compras/comprobante",
    Name: "Comprobante compra",
    Component: ComprobanteCompraList,
    parentId: 10,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.COMPRAS],
  },
];
const routeInventarios = [
  {
    id: 17,
    to: "Productos",
    path: "Productos",
    Name: "Producto/Servicios",
    Component: null,
    parentId: null,
    icon: <AppstoreOutlined />,
    isLink: false,
    isVisible: true,
    roles: [],
  },
  {
    id: 18,
    to: "/productos",
    path: "productos",
    Name: "Productos",
    Component: ProductosList,
    parentId: 17,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 19,
    to: "/items/listaprecio",
    path: "items/listaprecio",
    Name: "Lista de precios",
    Component: ListaPrecioList,
    parentId: 17,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 20,
    to: "Inventario",
    path: "Inventario",
    Name: "Inventario",
    Component: null,
    parentId: null,
    icon: <ControlOutlined />,
    isLink: false,
    isVisible: true,
    roles: [],
  },
  {
    id: 21,
    to: "/inventario/existencias",
    path: "inventario/existencias",
    Name: "Existencias",
    Component: InventarioProductoList,
    parentId: 20,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.ALMACEN],
  },
  {
    id: 121,
    to: "/inventario/movimientos",
    path: "inventario/movimientos",
    Name: "Movimientos",
    Component: MovimientosList,
    parentId: 20,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.ALMACEN],
  },
  {
    id: 22,
    to: "/inventario/traslados",
    path: "inventario/traslados",
    Name: "Traslados",
    Component: MovimientoTrasladoList,
    parentId: 20,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.ALMACEN],
  },
  {
    id: 23,
    to: "/inventario/kardex",
    path: "inventario/kardex",
    Name: "Kardex",
    Component: KardexList,
    parentId: 20,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [GroupsOfUser.ALMACEN],
  },
  {
    id: 120,
    to: "/inventario/movimientoentrada",
    path: "inventario/movimientoentrada",
    Name: "Movimiento entrada",
    Component: MovimientoEntradaScreen,
    parentId: null,
    icon: null,
    isLink: true,
    isVisible: false,
    roles: [GroupsOfUser.ALMACEN],
  },
];
const routeProduccion = [
  {
    id: 24,
    to: "Produccion",
    path: "Produccion",
    Name: "Producción",
    Component: null,
    parentId: null,
    icon: <ScheduleOutlined />,
    isLink: false,
    isVisible: true,
    roles: [],
  },
  {
    id: 25,
    to: "produccion/mantenimientos",
    path: "produccion/mantenimientos",
    Name: "Mantenimientos",
    Component: null,
    parentId: 24,
    icon: <FolderOutlined />,
    isLink: false,
    isVisible: true,
    roles: [],
  },
  {
    id: 26,
    to: "/produccion/mantenimiento/maquina",
    path: "produccion/mantenimiento/maquina",
    Name: "Maquina",
    Component: MaquinaList,
    parentId: 25,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 27,
    to: "/produccion/mantenimiento/tareaproduccion",
    path: "produccion/mantenimiento/tareaproduccion",
    Name: "Tarea producción",
    Component: TareaProduccionList,
    parentId: 25,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 28,
    to: "/produccion/mantenimiento/rutaaproduccion",
    path: "produccion/mantenimiento/rutaaproduccion",
    Name: "Ruta producción",
    Component: RutaProduccionList,
    parentId: 25,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 29,
    to: "produccion/procesos",
    path: "produccion/procesos",
    Name: "Procesos",
    Component: null,
    parentId: 24,
    icon: <FolderOutlined />,
    isLink: false,
    isVisible: true,
    roles: [],
  },
  {
    id: 30,
    to: "/produccion/procesos/ordenproduccion",
    path: "produccion/procesos/ordenproduccion",
    Name: "Orden producción",
    Component: OrdenProduccionList,
    parentId: 29,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 31,
    to: "/produccion/procesos/procesoproduccion",
    path: "produccion/procesos/procesoproduccion",
    Name: "Proceso producción",
    Component: ProcesoProduccionList,
    parentId: 29,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 311,
    to: "/produccion/procesos/resumenproduccion",
    path: "produccion/procesos/resumenproduccion",
    Name: "Resumen producción",
    Component: ResumenProduccionList,
    parentId: 29,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 32,
    to: "produccion/programacion",
    path: "produccion/programacion",
    Name: "Programación",
    Component: null,
    parentId: null,
    icon: <ReconciliationOutlined />,
    isLink: false,
    isVisible: true,
    roles: [],
  },
  {
    id: 33,
    to: "/produccion/programacion/tejido",
    path: "produccion/programacion/tejido",
    Name: "Programación Tejido",
    Component: ProgramacionTejidoList,
    parentId: 32,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 34,
    to: "/produccion/programacion/tintoreria",
    path: "produccion/programacion/tintoreria",
    Name: "Programación Tintoreria",
    Component: ProgramacionTintoreriaList,
    parentId: 32,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
];
const routeConfiguracion = [
  {
    id: 35,
    to: "configuraciones",
    path: "configuraciones",
    Name: "Configuraciones",
    Component: null,
    parentId: null,
    icon: <SettingOutlined />,
    isLink: false,
    isVisible: true,
    roles: [],
    initialConfig: true,
  },

  {
    id: 36,
    to: "/configuraciones/local",
    path: "configuraciones/local",
    Name: "Establecimientos",
    Component: LocalesList,
    parentId: 35,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
    initialConfig: true,
  },
  {
    id: 37,
    to: "/configuraciones/contactos",
    path: "configuraciones/contactos",
    Name: "Contactos",
    Component: ContactoList,
    parentId: 35,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 38,
    to: "/configuraciones/empresas",
    path: "configuraciones/empresas",
    Name: "Empresas",
    Component: EmpresasList,
    parentId: 35,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
    initialConfig: true,
  },
  {
    id: 42,
    to: "/configuraciones/proveedores",
    path: "configuraciones/proveedores",
    Name: "Proveedores",
    Component: ProveedorList,
    parentId: 35,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 43,
    to: "/configuraciones/generales",
    path: "configuraciones/generales",
    Name: "Generales",
    Component: GeneralesList,
    parentId: 35,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
  {
    id: 39,
    to: "seguridad",
    path: "seguridad",
    Name: "Seguridad",
    Component: null,
    parentId: null,
    icon: <ApartmentOutlined />,
    isLink: false,
    isVisible: true,
    roles: [],
    initialConfig: true,
  },
  {
    id: 40,
    to: "/personal/empleado",
    path: "personal/empleado",
    Name: "Empleados",
    Component: EmpleadoList,
    parentId: 39,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
    initialConfig: true,
  },
  {
    id: 41,
    to: "/configuraciones/usuario",
    path: "configuraciones/usuario",
    Name: "Usuarios",
    Component: UsuarioList,
    parentId: 39,
    icon: null,
    isLink: true,
    isVisible: true,
    roles: [],
  },
];
const routes = [
  ...routesGeneral,
  ...routeVentas,
  ...routeCompras,
  ...routeInventarios,
  ...routeProduccion,
  ...routeConfiguracion,
];
export default routes;
