import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { get_tiposcontacto } from "../../../actions/contacto";

export const TiposContactoSelect = ({ ...props }) => {
  const [datos, setDatos] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_tiposcontacto();
      setDatos(datos);
    };
    OnLoad();
  }, []);

  return <SelectUi mode="multiple" data={datos} {...props} />;
};
