import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContactoTable } from "./ContactoTable";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ContactoModal } from "./modal/ContactoModal";
import { contactosStartLoad } from "../../../actions/contacto";

export const ContactoList = () => {
  const dispatch = useDispatch();
  const { contactos, loading } = useSelector((state) => state.contacto);
  const [openModal, setOpenModal] = useState(false);
  const handleActualizar = () => {
    dispatch(contactosStartLoad());
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  useEffect(() => {
    dispatch(contactosStartLoad());
  }, []);
  return (
    <PageHeaderUi
      title="Contacto"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleOpenModal}
          key="Nuevo"
        />,
      ]}
    >
      <ContactoTable
        data={contactos}
        loading={loading}
        handleOpenModal={handleOpenModal}
      />
      <ContactoModal open={openModal} handleCancel={handleCloseModal} />
    </PageHeaderUi>
  );
};
// export const ContactoList = () => {
//   const { visibleForm } = useSelector((state) => state.ui);
//   if (visibleForm) {
//     return <ContactoScreen />;
//   } else {
//     return <ContactoListScreen />;
//   }
// };
