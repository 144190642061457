import React, { useEffect, useState } from "react";
import { SelectUi } from "../../../../ui/SelectUi";
import { get_tiposmovimientoentrada_porproduccion } from "../../../../../actions/produccion";

export const TipoMovimientoPorProduccionSelect = ({ ...props }) => {
  const [tipomovimientos, setTipomovimientos] = useState([]);
  const OnLoadTipoMovimientos = async () => {
    const datos = await get_tiposmovimientoentrada_porproduccion();
    setTipomovimientos(datos);
  };
  useEffect(() => {
    OnLoadTipoMovimientos();
  }, []);

  return <SelectUi data={tipomovimientos} {...props} />;
};
